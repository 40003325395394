import styled from 'styled-components';
import ReactLoading from 'react-loading';

export const Container = styled.div`
  width: 90vw;
  max-width: 518px;
`;


export const WrapperBody = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

export const WrapperTable = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3%;
  margin-right: 3%;
  margin-top:3%;
  
`;

export const WrapperBottom = styled.div`
  height: 66px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BottomText = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #122d58;
  cursor: pointer;
  padding: 5px 20px;

`;

export const Loading = styled(ReactLoading).attrs({
  color: '#122d58',
})``;

export const Text = styled.span`
  margin-top: 15px;
  color: #122d58;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
`;

export const TextDescription = styled.span`
  align-self: flex-start;
  margin-top: 15px;
  color: #122d58;
  font-size: 14px;
  font-weight: 600;
  `;
  



import React, { useEffect, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import Paper from 'components/Paper';
import Modal from './components/Modal';
import Table from 'components/Table';
import SearchInput from 'components/SearchInput';
import { Row } from 'styles/components';
import { format } from 'date-fns';
import TicketsActions from 'store/ducks/tickets';
import { useDispatch, useSelector } from 'react-redux';
import TooltipIcon from 'components/TooltipIcon';

import {
    Container,
    WrapperTable,
    EyeIcon,
    Payment,
    TicketIcon,
    WrapperPopover,
    PopoverText,
    PopoverButton,
    Loading
} from './styles'

export default function Ticket() {

    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(null);
    const [search, setSearch] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const userActive = useSelector((state) => state.auth.userActive);
    const isLoading = useSelector((state) => state.tickets.isLoading);

    const tickets = useSelector((state) => state.tickets);

    const renderTooltip = (Icon, mouseOver) => (

        <TooltipIcon
            placement="top-start"
            backgroundColor='#FF6464'
            fontSize='10px'
            color='white'
            mouseOver={mouseOver}
            Icon={Icon}
        />

    );

    const data = tickets?.tickets.map((item) => (
        {
            ...item,
            branch: item.branch,
            actions: item.branch + item.number + item.portion,
            cnpj: item.cnpj,
            social_reason: item.social_reason,
            fantasy_name: item.fantasy_name,
            number: item.number,
            type: item.type,
            portion: item.portion,
            created_at: new Date(item?.created_at.substr(0, 4), item?.created_at.substr(5, 2) - 1, item?.created_at.substr(8, 2)),
            due_date: new Date(item?.due_date.substr(0, 4), item?.due_date.substr(5, 2) - 1, item?.due_date.substr(8, 2)),
            value: item.value,
            recno: item.recno,
            email: userActive.email
        }));

    const filteredData = data ? data.filter(
        (item) =>
            item.cnpj.replace(/\D/g, "").includes( search.replace(/\D/g, "") ) ||
            item.social_reason.toUpperCase().includes(search.toUpperCase()) ||
            item.number().includes(search)
    ) : []

    //------------------------------------------------------------
    //Faz a solicitacao de recebimento do boleto por email
    //------------------------------------------------------------
    const receiveTicket = () => {

        const datafilter = data.filter((item) => item.branch + item.number + item.portion === selectedId)
        const [ticket] = datafilter

        dispatch(TicketsActions.receiveTicketsRequest(ticket));

    }

    useEffect(() => {
        dispatch(TicketsActions.getTicketsRequest());
    }, [dispatch]);

    return (

        <Container>

            <Loading
                active={isLoading}
                spinner
                text='Processando...'
            >
                <Paper
                    title="Boletos"
                    icon={<Payment />}
                    rightContent={
                        <SearchInput
                            onChange={(e) => setSearch(e.target.value)}
                            onClear={() => setSearch('')}
                            value={search}
                        />
                    }
                >
                    <WrapperTable>
                        <Table
                            columns={[
                                { label: 'Ações', id: 'actions' },
                                { label: 'Empresa', id: 'cnpj' },
                                { label: 'Razão Social', id: 'social_reason' },
                                { label: 'Nome Fantasia', id: 'fantasy_name' },
                                { label: 'Numero', id: 'number' },
                                { label: 'Parcela', id: 'portion' },
                                { label: 'Emissao', id: 'created_at' },
                                { label: 'Vencimento', id: 'due_date' },
                                { label: 'Valor', id: 'value' },
                            ]}
                            activePage={0}
                            totalPages={2}
                            data={filteredData}
                            customComponent={{
                                actions: (id) => (
                                    <Row>
                                        {renderTooltip(<EyeIcon
                                            onClick={() => {
                                                setSelectedId(id);
                                                setOpenModal(true);
                                            }}
                                        />,
                                            "Visualizar")}
                                        {renderTooltip(<TicketIcon
                                            onClick={(e) => {
                                                setSelectedId(id);
                                                setOpen(true)
                                                setAnchorEl(e.currentTarget);
                                                setOpenModal(false);
                                            }}
                                        />,
                                            "Solicitar boleto")}
                                    </Row>
                                ),
                                created_at: (e) => format(new Date(e), 'dd/MM/yyyy'),
                                due_date: (e) => format(new Date(e), 'dd/MM/yyyy'),
                                value: (e) => e.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                            }}
                        />
                    </WrapperTable>
                </Paper>

                {filteredData ?
                    <Modal
                        data={filteredData}
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        selectedId={selectedId}
                        tickets={filteredData}
                    /> : null
                }

                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setOpen(false);
                        setSelectedId(null);
                    }}
                    placement="top"
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <WrapperPopover>
                        <PopoverText>Confirma solicitação de boleto?</PopoverText>
                        <PopoverButton
                            onClick={() => {
                                setOpen(false);
                                receiveTicket()
                            }}
                        >
                            Sim
                        </PopoverButton>
                    </WrapperPopover>
                </Popover>

            </Loading>
        </Container >
    )

}
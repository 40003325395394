/* eslint-disable no-nested-ternary */
import React from 'react';
import { bool, func, shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Modal from 'components/Modal';
import InputMask from 'react-input-mask';

import TextField from 'components/TextField'
import { schema } from './schemaValidation';
import { schemaPassword } from './schemaValidationPassword';
import UsersActions from 'store/ducks/users';

import {
  Container,
  WrapperBody,
  WrapperContent,
  WrapperBottom,
  BottomText,
  WrapperInput,
  useStyles,
  Input,
  WrapperPhone,
  WhatsappIcon,
  PhoneIcon,
  AccountIcon,
  WrapperIcon,
  Identify,
  EmailIcon,
  UserLogin
} from './styles';

function ModalComp({ open, updatePassword, onClose }) {

  const dispatch = useDispatch();
  const classes = useStyles();
  const userActive = useSelector((state) => state.auth.userActive);

  function handleSubmitted(values) {

    if ( !updatePassword ){
      dispatch(UsersActions.updateUserRequest({ ...values }, onClose));
    }else{
      dispatch(UsersActions.updatePasswordRequest({ ...values }, onClose));
    }

  }

  return (

    <Modal open={open} onClose={onClose} title={updatePassword ? "ALTERAR SENHA" : "PERFIL DO USUÁRIO"}>

      {!updatePassword ?
        <Container>
          <Formik
            enableReinitialize
            initialValues={{
              name: userActive.name,
              email: userActive.email,
              phone: userActive.phone,
              cellphone: userActive.cellphone,
              cpf: userActive.cpf,
              password: '********',
            }}
            onSubmit={(values) => handleSubmitted(values)}
            validationSchema={schema()}
          >
            {({ values, handleChange, errors, touched, handleSubmit, handleBlur }) => (
              <>
                <WrapperBody>
                  <WrapperContent>
                    <>
                      <WrapperInput>

                        <TextField
                          color="secondary"
                          placeholder="Nome"
                          name="name"
                          helperText={touched.name ? errors.name : ''}
                          error={touched.name && Boolean(errors.name)}
                          value={values.name}
                          onChange={handleChange}
                          icon={<AccountIcon />}
                        />
                      </WrapperInput>

                    </>

                    <WrapperInput className={classes.icon}>

                      <WrapperIcon> <Identify /> </WrapperIcon >
                      <InputMask
                        id="cpf"
                        mask={"999.999.999-99"}
                        label="CPF"
                        className={classes.mask}
                        placeholder="CPF"
                        error={touched.cpf && Boolean(errors.cpf)}
                        value={values.cpf}
                        onChange={handleChange}
                        onBlur={handleBlur}

                      >

                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </WrapperInput>

                    <WrapperPhone className={classes.icon}>

                      <WrapperIcon> <PhoneIcon /> </WrapperIcon >

                      <WrapperInput style={{ paddingRight: 8 }}>
                        <InputMask
                          src={<AccountIcon />}
                          id="phone"
                          mask="(99) 9999-9999"
                          label="Telefone"
                          className={classes.mask}
                          placeholder="Telefone"
                          error={touched.phone && Boolean(errors.phone)}
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {(inputProps) => <Input {...inputProps} />}
                        </InputMask>
                      </WrapperInput>


                      <WrapperIcon><WhatsappIcon /></WrapperIcon >
                      <WrapperInput >
                        <InputMask
                          id="cellphone"
                          mask="(99) 99999-9999"
                          label="cellphone"
                          className={classes.mask}
                          placeholder="Celular"
                          error={touched.cellphone && Boolean(errors.cellphone)}
                          value={values.cellphone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {(inputProps) => <Input {...inputProps} />}
                        </InputMask>
                      </WrapperInput>
                    </WrapperPhone>

                    <WrapperInput>
                      <TextField
                        color="secondary"
                        placeholder="E-mail"
                        name="email"
                        helperText={touched.email ? errors.email : ''}
                        error={touched.email && Boolean(errors.email)}
                        value={values.email}
                        icon={<EmailIcon />}
                        disabled
                      />
                    </WrapperInput>
                  </WrapperContent>
                </WrapperBody>
                <WrapperBottom>
                  <BottomText onClick={onClose}>Cancelar</BottomText>

                  <BottomText onClick={handleSubmit}>Salvar</BottomText>
                </WrapperBottom>
              </>
            )}
          </Formik>

        </Container>

        // --------------------------------------------------------------------------  
        // Tela para Alteracao de senha
        // --------------------------------------------------------------------------  
        :
        <Container>

          <Formik
            enableReinitialize
            initialValues={{
              email: userActive.email,
              newPassword: '',
              checkPassword: ''
            }}
            onSubmit={(values) => handleSubmitted(values)}
            validationSchema={schemaPassword()}
          >
            {({ values, handleChange, errors, touched, handleSubmit }) => (
              <>
                <WrapperBody>
                  <WrapperContent>
                    <>
                      <WrapperInput>
                        <TextField
                          label="Login"
                          color="secondary"
                          placeholder="Login"
                          name="email"
                          helperText={touched.email ? errors.email : ''}
                          error={touched.email && Boolean(errors.email)}
                          value={values.email}
                          icon={<UserLogin />}
                          disabled
                        />
                      </WrapperInput>

                      <WrapperInput>
                        <TextField
                          label="Nova Senha"
                          name="newPassword"
                          type="password"
                          helperText={touched.newPassword ? errors.newPassword : ''}
                          error={Boolean(errors.newPassword)}
                          value={values.newPassword}
                          onChange={handleChange}
                          color="secondary"
                          fullWidth
                        />
                      </WrapperInput>


                      <WrapperInput>
                        <TextField
                          label="Confirmar nova Senha"
                          name="checkPassword"
                          type="password"
                          helperText={touched.checkPassword ? errors.checkPassword : ''}
                          error={touched.checkPassword && Boolean(errors.checkPassword)}
                          value={values.checkPassword}
                          onChange={handleChange}
                          color="secondary"
                          fullWidth
                        />
                      </WrapperInput>

                    </>
                  </WrapperContent>

                </WrapperBody>
                <WrapperBottom>
                  <BottomText onClick={onClose}>Cancelar</BottomText>

                  <BottomText onClick={handleSubmit}>Salvar</BottomText>
                </WrapperBottom>
              </>
            )}
          </Formik>

        </Container>
      }

    </Modal>

  );
}

export default ModalComp;

ModalComp.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  data: shape().isRequired,
};


import React from 'react';
import { string, oneOfType, bool, element } from 'prop-types';

import { Container, WrapperTop, Title, Content, Icon, Shimmer } from './styles';

function InfoCard({
  title,
  backgroundColor,
  content,
  titleColor,
  contentColor,
  icon,
  type,
  loading,
}) {
  return (
    <Container backgroundColor={backgroundColor} type={type}>
      <WrapperTop>
        {typeof icon === 'string' ? <Icon src={icon} /> : icon}
        <Title titleColor={titleColor}>{title}</Title>
      </WrapperTop>
      {loading ? (
        <Shimmer />
      ) : (
        <Content contentColor={contentColor}>{content}</Content>
      )}
    </Container>
  );
}

export default InfoCard;

InfoCard.propTypes = {
  title: string,
  backgroundColor: string,
  content: string,
  titleColor: string,
  contentColor: string,
  icon: oneOfType([string, element]),
  type: string,
  loading: bool,
};

InfoCard.defaultProps = {
  title: '',
  backgroundColor: '#435B82',
  content: '',
  titleColor: '#fff',
  contentColor: '#fff',
  icon: null,
  type: 'primary',
  loading: false,
};

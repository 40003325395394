import { makeStyles,withStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Label = styled.span`
color: #7b7b7b;
font-size: 11px;
font-weight: 700;
white-space: nowrap;
margin-bottom: 6px;
`;


import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { MdAddCircle, MdAddCircleOutline, MdDescription, MdExitToApp, MdPlayCircleOutline } from 'react-icons/md';
import { FaFileInvoice, FaUser, FaUserCog, FaKey, FaImages } from 'react-icons/fa';
import { MdLibraryBooks, MdDashboard, MdAttachMoney } from "react-icons/md"
import { GiMoneyStack } from "react-icons/gi"

const drawerWidth = 260;

export const Wrapper = styled.div`
  height: 100%;
  background: #f0f0f0;
`;

export const Logo = styled.img`
  width: 17em;
  height: 3em;
  
  
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#145b99',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    colorInherit: "red",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',


  },
  drawerOpen: {
    width: drawerWidth,
    //  backgroundColor: '#145b99',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    // backgroundColor: '#145b99',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const ContainerProfile = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WrapperProfile = styled.div`
  height: 35px;
  width: 35px;
  border: 2px solid #fff;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
 
`;

export const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PopoverRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  cursor: pointer;
`;

export const ExitIcon = styled(MdExitToApp).attrs({ size: '18px' })`
  color: #145b99;
  margin-right: 13px;
`;

export const ProfileIcon = styled(FaUser).attrs({ size: '18px' })`
  color: #145b99;
  margin-right: 13px;
`;


export const KeyIcon = styled(FaKey).attrs({ size: '18px' })`
  color: #145b99;
  margin-right: 13px;
`;

export const Configure = styled(FaUserCog).attrs({ size: '24px' })`
  color: #fff;

`;

export const PopoverText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #7b7b7b;
`;

export const Home = styled(MdDashboard).attrs({
  size: '23px',
})`
  color: #7b7b7b;
  cursor: pointer;

`;


export const Report = styled(MdLibraryBooks).attrs({
  size: '23px',
})`
  color: #7b7b7b;
  cursor: pointer;

`;

export const ItemReport = styled(MdDescription).attrs({
  size: '20px',
})`
  color: #7b7b7b;
  cursor: pointer;

`;


export const Payment = styled(MdAttachMoney).attrs({
  size: '29px',

})`
  color: #7b7b7b;
  cursor: pointer;
`;

export const Invoices = styled(FaFileInvoice).attrs({
  size: '23px',
})`
  color: #7b7b7b;
  cursor: pointer;

`;


export const IconReceive = styled(GiMoneyStack).attrs({
  size: '28px',
})`
  color: #7b7b7b;
  cursor: pointer;
  vertical-align:'center';
`;

export const CatalogIcon = styled(FaImages).attrs({
  size: '26px',
})`
  color: #7b7b7b;
  cursor: pointer;
  vertical-align:'center';
`;

export const Collumn = styled.div`
  display: flex;
  flex-direction: column;
`

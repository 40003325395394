import * as yup from 'yup';

export const schema = () => {
  return yup.object().shape({
    name: yup.string().min(3).required('Nome é obrigatório'),
    cpf: yup.string().min(11).required('CPF é obrigatório'),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    password: yup.string().min(6).required('Senha é obrigatória'),
  });
};

import Paper from 'components/Paper';
import DateInput from 'components/DateInput';
import { useSelector } from 'react-redux';
import InfoCard from 'components/InfoCard';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';

import DashboardActions from 'store/ducks/dashboard';
import MultiSelected from 'components/MultiSelect';
import {
  Container,
  Home,
  IconMoney,
  InvoicesIcon,
  Grid,
  ItemGrid,
  Filters,
  ItemFilter,
  WrapperButton,
  WrapperFilter,
} from './styles';

export default function Dashboard() {
  const dispatch = useDispatch();
  const date = new Date();
  const isLoading = useSelector((state) => state.dashboard.isLoading);
  const dashboard = useSelector((state) => state.dashboard);
  const userActive = useSelector((state) => state.auth.userActive);
  const { tickets, invoices, ticketsExpired, requests, credit } =
    dashboard.dashboard;
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [selected, setSelected] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);

  const dataCompany = userActive.roles_access?.company?.branches.map(
    (item) => ({ label: item[0][0] + '-' + item[0][1], value: item[0][0] })
  );

  const group = userActive.roles_access?.company?.group.map((item) => ({
    label: item[0][0] + '-' + item[0][1],
    value: item[0][0],
  }));

  const handleFilter = () => {
    const dataFilter = {
      startDate: startDate,
      endDate: endDate,
      filter: {
        company: selected,
        group: selectedGroup,
      },
    };

    dispatch(DashboardActions.getDashboardRequest(dataFilter));
  };

  useEffect(() => {
    setSelected(dataCompany);
    setSelectedGroup(group);

    const dataFilter = {
      startDate: startDate,
      endDate: endDate,
      filter: {
        company: dataCompany,
        group: group,
      },
    };

    dispatch(DashboardActions.getDashboardRequest(dataFilter));
  }, [dispatch]);

  return (
    <Container>
      <Paper title="DashBoard" icon={<Home />}>
        <WrapperFilter>
          <Filters>
            <ItemFilter>
              <DateInput
                name="created_at"
                label="Periodo"
                height="42px"
                placeholder="00/00/0000 - 00/00/0000"
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                }}
              />
            </ItemFilter>
            <ItemFilter>
              <MultiSelected
                label="Empresa"
                options={dataCompany}
                value={selected}
                onChange={setSelected}
                hasSelectAll={false}
                disableSearch={true}
              />
            </ItemFilter>

            <ItemFilter>
              <MultiSelected
                label="Grupo"
                options={group}
                value={selectedGroup}
                onChange={setSelectedGroup}
                hasSelectAll={false}
                disableSearch={true}
              />
            </ItemFilter>
          </Filters>

          <WrapperButton>
            <Button
              type="secondary"
              label="Aplicar"
              width="100px"
              height="33px"
              margin="0px 20px 0px"
              onClick={handleFilter}
            />
          </WrapperButton>
        </WrapperFilter>
        <Grid>
          <ItemGrid>
            <InfoCard
              title="TITULOS VENCIDOS"
              content={Number(ticketsExpired || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              icon={<IconMoney />}
              backgroundColor="#B33356"
              loading={isLoading}
            />
          </ItemGrid>
          <ItemGrid>
            <InfoCard
              title="TITULOS A VENCER"
              content={Number(tickets || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              icon={<IconMoney />}
              backgroundColor="#44DD30"
              loading={isLoading}
            />
          </ItemGrid>

          <ItemGrid>
            <InfoCard
              title="NOTAS FISCAIS"
              content={Number(invoices || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              icon={<InvoicesIcon />}
              backgroundColor="#AFAFAF"
              titleColor="#fff"
              contentColor="#fff"
              loading={isLoading}
            />
          </ItemGrid>
        </Grid>
        <Grid>
          <ItemGrid>
            <InfoCard
              title="PEDIDOS A LIBERAR"
              content={Number(requests?.boqueado || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              icon={<InvoicesIcon />}
              backgroundColor="#B33356"
              titleColor="#fff"
              contentColor="#fff"
              loading={isLoading}
            />
          </ItemGrid>

          <ItemGrid>
            <InfoCard
              title="PEDIDOS LIBERADOS"
              content={Number(requests?.liberado || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              icon={<InvoicesIcon />}
              backgroundColor="#ffb94d"
              titleColor="#fff"
              contentColor="#fff"
              loading={isLoading}
            />
          </ItemGrid>

          <ItemGrid>
            <InfoCard
              title="PEDIDOS PROGRAMADOS"
              content={Number(requests?.programado || 0).toLocaleString(
                'pt-BR',
                { style: 'currency', currency: 'BRL' }
              )}
              icon={<InvoicesIcon />}
              backgroundColor="#4363ba"
              titleColor="#fff"
              contentColor="#fff"
              loading={isLoading}
            />
          </ItemGrid>

          <ItemGrid>
            <InfoCard
              title="PEDIDOS FATURADOS"
              content={Number(requests?.faturado || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              icon={<InvoicesIcon />}
              backgroundColor="#44DD30"
              titleColor="#fff"
              contentColor="#fff"
              loading={isLoading}
            />
          </ItemGrid>
        </Grid>
      </Paper>
    </Container>
  );
}

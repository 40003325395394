import styled from 'styled-components';
import { FaImages } from 'react-icons/fa';
import { Button, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';

export const Container = styled.div`
width: 100%;
flex-direction: columnn;

`;

export const CatalogIcon = styled(FaImages).attrs({
  size: '25px',
})`
  color: #7b7b7b;
  cursor: pointer;
  margin-right:5px;

`;

export const DriverLink = withStyles({
  root: {
    height: 40,
    width: 200,
    backgroundColor: '#FFF28D',
    borderRadius: 3,
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 11,
    fontWeight: 700,
    color: '#145b99',
    margin: 30,
  },
})(Button);


export const Driver = styled.div`
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap:wrap;

`;

import styled from 'styled-components';

export const BannerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: contain; 
`;

export const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: transparent
    linear-gradient(
      90deg,
      #10285000 0%,
      #10285052 7%,
      #1028509e 16%,
      #102850d4 45%,
      #102850f5 55%,
      #102850 57%,
      #102850 100%
    )
    0% 0% no-repeat padding-box;
`;


export const ContentWrapper = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

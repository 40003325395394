import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getUserRequest: null,
  getUserSuccess: ['userActive'],
  getUserFailure: ['error'],
  updateUserRequest: ['userActive', 'closeModal'],
  updateUserSuccess: ['userActive'],
  updateUserFailure: ['error'],
  updatePasswordRequest: ['password', 'closeModal'],
  updatePasswordSuccess: ['password'],
  updatePasswordFailure: ['error'],
});

export const UsersTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  userActive: null,
  isLoading: false,
  error: null,
  openModal: false,
});


const getUserRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getUserSuccess = (state, { userActive }) =>
  state.merge({
    userActive,
    isLoading: false,
  });

const getUserFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });


const updateUserRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const updateUserSuccess = (state, { userActive }) =>
  state.merge({
    userActive: userActive,
    isLoading: false,
    openModal: false,
  });
const updateUserFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });


const updatePasswordRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const updatePasswordSuccess = (state, { userActive }) =>
  state.merge({
    userActive: userActive,
    isLoading: false,
    openModal: false,
  });
const updatePasswordFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });


export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_REQUEST]: getUserRequest,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAILURE]: getUserFailure,
  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
  [Types.UPDATE_PASSWORD_REQUEST]: updatePasswordRequest,
  [Types.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
  [Types.UPDATE_PASSWORD_FAILURE]: updatePasswordFailure,
});

import React from 'react';
import { string, func } from 'prop-types';

import { Button, Icon } from './styles';

function ButtonComp({ icon, width, label, type, onClick,margin }) {
  return (
    <Button width={width} type={type} onClick={onClick} margin={margin}>
      {icon !== null && <Icon src={icon} />} {label}
    </Button>
  );
}

export default ButtonComp;

ButtonComp.propTypes = {
  width: string,
  label: string.isRequired,
  type: string,
  onClick: func,
  icon: string,
  margin: string,
};

ButtonComp.defaultProps = {
  width: null,
  icon: null,
  type: 'primary',
  onClick: () => {},
  margin:null
};

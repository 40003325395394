import React from 'react';
import Paper from 'components/Paper';

import { Container, IconReceive } from './styles';

export default function Reports() {
  return (
    <Container>
      <Paper title="Relatórios" icon={<IconReceive />}></Paper>
    </Container>
  );
}

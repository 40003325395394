import React from 'react';
import { string, func, bool } from 'prop-types';

import { Container, Label, Input } from './styles';

function TextInput({
  ref,
  id,
  name,
  label,
  value,
  onChange,
  onBlur,
  placeholder,
  disabled,
  error,
  type,
  pattern,
  height,
}) {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Input
        ref={ref}
        pattern={pattern}
        type={type}
        name={name}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        height={height}
      />
    </Container>
  );
}

export default TextInput;

TextInput.propTypes = {
  ref: string,
  pattern: string,
  type: string,
  id: string,
  name: string,
  label: string,
  value: string,
  onChange: func,
  onBlur: func,
  placeholder: string,
  disabled: bool,
  error: bool,
  height: string,
};

TextInput.defaultProps = {
  ref: null,
  pattern: '',
  type: 'text',
  id: null,
  name: null,
  label: '',
  value: null,
  onChange: () => {},
  onBlur: () => {},
  placeholder: 'Digite aqui...',
  disabled: false,
  error: false,
  height: '32px',
};

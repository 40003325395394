import { put, select } from 'redux-saga/effects';
import api from 'services/api'
import TicketsActions from 'store/ducks/tickets';
import { actions as toastrActions } from 'react-redux-toastr';
import localStorage from 'local-storage';

//-------------------------------------------------
//Busca titulos em aberto para emissao de boletos
//-------------------------------------------------
export function* getTickets() {

  const sectionActive = localStorage.get("section")
  const userActive = yield select((state) => state.auth.userActive);
  const company = userActive.roles_access.company
  const cpf     = userActive.cpf

  try {

    const { token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("tickets", { company,cpf })
    const { data } = response

    const tickets = data.tickets.map((item) => ({ ...item }))

    //valido retorno
    if (response.status === 200) {

      yield put(TicketsActions.getTicketsSuccess(tickets))

    }

  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao buscar boletos',
        message: 'Tente novamente',
      })
    );

    yield put(TicketsActions.getTicketsFailure(err));

  }
}

export function* receiveTickets(ticket) {

  try {

    const sectionActive = localStorage.get("section")
    const { token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("tickets/receive", { ticket })

    //valido retorno
    if (response.status === 200) {

      yield put(TicketsActions.receiveTicketsSuccess())

      yield put(
        toastrActions.add({
          type: 'success',
          title: 'Boleto',
          message: 'Boleto enviado com sucesso!',
        })
      );

    }

  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao solicitar envio boleto',
        message: 'Tente novamente',
      })
    );

    yield put(TicketsActions.getTicketsFailure(err));



  }
}



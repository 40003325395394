import { bool, element, func, number, string } from 'prop-types';
import React from 'react';
import {
  Container,
  FootLight,
  FootThick,
  FootWrapper,
  Title,
  WrapperContent,
} from './styles';

function Section({
  title,
  fontSize,
  children,
}) {
  return (
    <Container >
      <WrapperContent>
        <Title fontSize={fontSize}>{title}</Title>
        <FootWrapper>
          <FootThick />
          <FootLight />
        </FootWrapper>
      {children}
      </WrapperContent>
    </Container>
  );
}

export default Section;
Section.propTypes = {
  title: string,
  isShown: bool,
  setIsShown: func,
  fontSize:number,
  children: element,
};

Section.defaultProps = {
  title: '',
  isShown: true,
  children: null,
  fontSize:null,
  setIsShown: () => {}
};

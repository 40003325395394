
import { MdAddCircle } from 'react-icons/md';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';

export const useStyles = makeStyles(() => ({

  available:{
    color: 'red',
  },

}))

export const Container = styled.div``;

export const AddIcon = styled(MdAddCircle).attrs({ size: '30px' })`
margin: 10px;
color: #7b7b7b;
`;

export const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
  height: 55px;
  padding: 10px 35px 0px 0px;
  justify-content: flex-end;
  background-color: #ebebeb;
  border-radius: 5px;
  margin-top: 30px;
`

export const FieldText = styled.p`
  color: #145b99;
  font-size: 13px;
  font-weight: bold;
`
export const FieldValue = styled.p`
  color: #145b99;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
`

export const WrapperField = styled.div`
display: flex;
flex-direction: column;
width: 15%;
max-width: 150px;
margin: 30px; 
`


export const Row = styled.div`
display: flex;
flex-direction: row;
`

export const WrapperInput = styled.div`
  padding: 15px 0;
  width: 100%;
`;

export const Loading = styled(LoadingOverlay).attrs({
  color: '#122d58',
  styles: {
    overlay: (base) => ({
      ...base,
      '& svg circle': {
        stroke: '#fff'
      },
      height:'100%',
      color:'#fff',
      fontSize: 13,
      backgroundColor: '#122d58',
      opacity: 0.6,
      fontWeight: 600,
    }),
    fontWeight: 600
  }
})``;
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: ${(props) => (props.type === 'primary' ? '158px' : '61px')};
  background-color: ${(props) => props.backgroundColor};
  box-shadow: -3px 3px 6px #0000000f;
  border-radius: 5px;
  padding: 22px 22px 22px 43px;
  display: flex;
  flex-direction: ${(props) => (props.type === 'primary' ? 'column' : 'row')};
  align-items: ${(props) =>
    props.type === 'primary' ? 'flex-start' : 'center'};
  justify-content: space-between;
`;

export const WrapperTop = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  height: 26px;
`;

export const Title = styled.span`
  margin-left: 21px;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.titleColor};
`;

export const Content = styled.p`
  font-size: 25px;
  font-weight: 900;
  color: ${(props) => props.contentColor};
  white-space: nowrap;
`;

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
`;

export const Shimmer = styled.div`
  animation: ${shimmer} 2s infinite linear;
  height: 30px;
  width: 60%;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
  opacity: 0.6;
`;


import React from 'react';
import Paper from 'components/Paper';
import { useSelector } from 'react-redux';

import {
    Container,
    CatalogIcon,
    DriverLink,
    Driver
} from './styles'

export default function Catalogs() {

    const userActive = useSelector((state) => state.auth.userActive);
    const { driver } = userActive.roles_access.driver

    return (
        <Container>
            <Paper
                title="Catálogo"
                icon={<CatalogIcon />}
            >

                <Driver>
                    {driver.map((item) => (

                        <DriverLink href={item[0][1]} target="_blank">
                            {item[0][0]}
                        </DriverLink>

                    ))}
                </Driver>
            </Paper>
        </Container >
    )

}
import styled from 'styled-components';
import { MdSearch, MdClear } from 'react-icons/md';

export const Container = styled.div`
  height: 32px;
  background-color: #aeaeae37;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 12px;
  max-width: 90%;
`;

export const Input = styled.input.attrs({ type: 'text' })`
  background: none;
  border: none;
  color: #7b7b7b;
  font-weight: 600;
  ::placeholder {
    color: #7f7f7f;
    font-weight: 400;
  }
  flex: 1;
  width: 70%;
  min-width: 60px;
`;

export const SearchIcon = styled(MdSearch).attrs({ size: '16px' })`
  color: #7b7b7b;
  margin-right: 13px;
`;

export const ClearIcon = styled(MdClear).attrs({ size: '16px' })`
  color: #7b7b7b;
  margin-left: 13px;
  cursor: pointer;
`;

import * as yup from 'yup';

export const schema = () => {
  return yup.object().shape({
    emission_at: yup.string().required('Emissão Inicial é obrigatório'),
    emission_end: yup.string().required('Emissao Final é obrigatório'),
    drop_at: yup.string().required('Baixa Inicial é obrigatório'),
    drop_end: yup.string().required('Baixa Final é obrigatório')
  });
};

import { useDispatch, useSelector } from 'react-redux';
import TextField from 'components/TextField';
import Images from 'assets/img';
import AuthActions from 'store/ducks/auth';
import animationData from 'assets/animation.json';

import {
  Content,
  WrapperButtons,
  EnterButton,
  ButtonText,
  ForgotLink,
  Forgot,
  Logo,
  GridLoading,
} from './styles';

import { Formik } from 'formik';
import { schema } from './schemaValidation';
import { Redirect } from 'react-router';
import { useEffect } from 'react';
import { loginStatusWatcher } from 'store/saga/auth';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function Signin() {
  const dispatch = useDispatch();
  const signedIn = useSelector((state) => state.auth.signedIn);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const authChecked = useSelector((state) => state.auth.authChecked);

  const handleSubmitted = (values) => {
    dispatch(AuthActions.signInRequest(values.email, values.password));
  };

  useEffect(() => {
    //valido se tem token ativo
    loginStatusWatcher();
  });

  if (!authChecked || isLoading)
    return (
      <>
        <GridLoading>
          <Lottie options={defaultOptions} height={150} width={150} />
        </GridLoading>
      </>
    );

  return !signedIn ? (
    <Content>
      <Logo src={Images.Logo} />
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values) => handleSubmitted(values)}
        validationSchema={schema}
      >
        {({ values, handleChange, errors, touched, handleSubmit }) => (
          <>
            <TextField
              label="Email"
              name="email"
              helperText={touched.email ? errors.email : ''}
              error={Boolean(errors.email)}
              value={values.email}
              onChange={handleChange}
              fullWidth
              color="primary"
            />
            <TextField
              label="Senha"
              name="password"
              type="password"
              helperText={touched.password ? errors.password : ''}
              error={Boolean(errors.password)}
              value={values.password}
              onChange={handleChange}
              fullWidth
              color="primary"
            />
            <WrapperButtons>
              <EnterButton onClick={handleSubmit} color="primary">
                <ButtonText>ENTRAR</ButtonText>
              </EnterButton>
            </WrapperButtons>
          </>
        )}
      </Formik>
    </Content>
  ) : (
    <Redirect to="/" />
  );
}

import React from 'react';
import { string, func } from 'prop-types';

import { Container, Input, SearchIcon, ClearIcon } from './styles';

function SearchInput({ onChange, value, onClear, placeholder }) {
  return (
    <Container>
      <SearchIcon />
      <Input placeholder={placeholder} onChange={onChange} value={value} />
      <ClearIcon onClick={onClear} />
    </Container>
  );
}

export default SearchInput;

SearchInput.propTypes = {
  onChange: func,
  value: string,
  onClear: func,
  placeholder: string,
};

SearchInput.defaultProps = {
  onChange: () => {},
  value: '',
  onClear: () => {},
  placeholder: 'Buscar...',
};

import React from 'react';
import PropTypes from 'prop-types';
import { Container, ContentWrapper, BannerImage } from './styles';
import Images from 'assets/img';


export default function AuthLayout({ children }) {
  return (
    <>
      <BannerImage src={Images.Banner} />
      <Container>
        <ContentWrapper>{children}</ContentWrapper>
      </Container>
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

import { put, select } from 'redux-saga/effects';
import api from 'services/api'
import InvoicesActions from 'store/ducks/invoices';
import { actions as toastrActions } from 'react-redux-toastr';
import localStorage from 'local-storage';

//-------------------------------------------------
//Busca Notas fiscais para emissao de 2 via
//-------------------------------------------------
export function* getInvoices() {

  const sectionActive = localStorage.get("section")
  const userActive = yield select((state) => state.auth.userActive);
  const company = userActive.roles_access.company
  const cpf      = userActive.cpf

  try {

    const { token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("invoices", { company,cpf })
    const { data } = response

    const invoices = data.invoices.map((item) => ({ ...item }))

    //valido retorno
    if (response.status === 200) {

      yield put(InvoicesActions.getInvoicesSuccess(invoices))

    }

  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao buscar notas fiscais',
        message: 'Tente novamente',
      })
    );

    yield put(InvoicesActions.getInvoicesFailure(err));

  }
}

export function* receiveInvoices(invoice) {

  try {

    const sectionActive = localStorage.get("section")
    const { token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("invoices/receive", { invoice })

    //valido retorno
    if (response.status === 200) {

      yield put(InvoicesActions.receiveInvoicesSuccess())

      yield put(
        toastrActions.add({
          type: 'success',
          title: 'Nota Fiscal',
          message: 'Nota fiscal solicitada com sucesso!',
        })
      );

    }

  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao solicitar envio nota fiscal',
        message: 'Tente novamente',
      })
    );

    yield put(InvoicesActions.getInvoicesFailure(err));

  }
}



import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { schema } from './schemaValidation';
import AuthActions from 'store/ducks/auth';
import Images from 'assets/img';
import TextField from 'components/TextField';

import {
  Logo,
  Content,
  Text,
  WrapperButtons,
  EnterButton,
  BackButton,
  ButtonText,
} from './styles';

export default function Forgot() {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleSubmitted(values) {
    dispatch(AuthActions.forgotRequest(values.email));
  }

  function backToSignIn() {
    history.push('/signin');
  }

  return (
    <Content>
      <Logo src={Images.Logo} />
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
        }}
        onSubmit={(values) => handleSubmitted(values)}
        validationSchema={schema}
      >
        {({ values, handleChange, errors, touched, handleSubmit }) => (
          <Content>
            <Text>Digite um e-mail válido para recuperar sua senha.</Text>
            <TextField
              label="E-mail"
              name="email"
              helperText={touched.email ? errors.email : ''}
              error={Boolean(errors.email)}
              value={values.email}
              onChange={handleChange}
              fullWidth
              color="primary"
            />
            <WrapperButtons>
              <BackButton onClick={backToSignIn}>
                <ButtonText>VOLTAR</ButtonText>
              </BackButton>
              <EnterButton onClick={handleSubmit}>
                <ButtonText>ENVIAR</ButtonText>
              </EnterButton>
            </WrapperButtons>
          </Content>
        )}
      </Formik>
    </Content>
  );
}


import React, { useEffect, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import Paper from 'components/Paper';
import TooltipIcon from 'components/TooltipIcon';
import Table from 'components/Table';
import SearchInput from 'components/SearchInput';
import { Row } from 'styles/components';
import { format } from 'date-fns';
import InvoicesActions from 'store/ducks/invoices';
import { useDispatch, useSelector } from 'react-redux';

import {
    Container,
    WrapperTable,
    InvoicesIcon,
    TicketIcon,
    WrapperPopover,
    PopoverText,
    PopoverButton,
    Loading
} from './styles'

export default function Invoices() {

    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(null);
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const userActive = useSelector((state) => state.auth.userActive);
    const isLoading = useSelector((state) => state.invoices.isLoading);

    const invoices = useSelector((state) => state.invoices);

    const renderTooltip = (Icon, mouseOver) => (

        <TooltipIcon
            placement="top-start"
            backgroundColor='#FF6464'
            fontSize='10px'
            color='white'
            mouseOver={mouseOver}
            Icon={Icon}
        />

    );

    const data = invoices?.invoices.map((item) => (
        {
            ...item,
            branch: item.branch,
            actions: item.branch + item.doc_number + item.serie_number,
            constumer: item.constumer,
            store: item.store,
            social_reason: item.social_reason,
            fantasy_name: item.fantasy_name,
            doc_number: item.doc_number,
            serie_number: item.serie_number,
            value: item.value,
            key: item.key,
            created_at: new Date(item?.created_at.substr(0, 4), item?.created_at.substr(5, 2) - 1, item?.created_at.substr(8, 2)),
            recno: item.recno,
            email: userActive.email

        }));

    const filteredData = data ? data.filter(
        (item) =>
            item.constumer.replace(/\D/g, "").includes( search.replace(/\D/g, "") ) ||
            item.social_reason.toUpperCase().includes(search.toUpperCase()) ||
            item.fantasy_name.toUpperCase().includes(search.toUpperCase()) ||
            item.doc_number.includes(search) ||
            item.created_at.toLocaleString('pt-BR', { timeZone: 'UTC' }).substr(0, 10).includes(search)
    ) : []

    //------------------------------------------------------------
    //Faz a solicitacao de recebimento do nota fiscal por email
    //------------------------------------------------------------
    const receiveInvoices = () => {

        const datafilter = data.filter((item) => item.branch + item.doc_number + item.serie_number === selectedId)
        const [invoice] = datafilter

        dispatch(InvoicesActions.receiveInvoicesRequest(invoice));

    }

    useEffect(() => {
        dispatch(InvoicesActions.getInvoicesRequest());
    }, [dispatch]);


    return (
        <Container>
            <Loading
                active={isLoading}
                spinner
                text='Processando...'
            >

                <Paper
                    title="Notas Fiscais"
                    icon={<InvoicesIcon />}
                    rightContent={
                        <SearchInput
                            onChange={(e) => setSearch(e.target.value)}
                            onClear={() => setSearch('')}
                            value={search}
                        />
                    }
                >

                    <WrapperTable>
                        <Table
                            columns={[
                                { label: 'Ações', id: 'actions' },
                                { label: 'Empresa', id: 'constumer' },
                                { label: 'Razâo Social', id: 'social_reason' },
                                { label: 'Nome Fantasia', id: 'fantasy_name' },
                                { label: 'Nota Fiscal', id: 'doc_number' },
                                { label: 'Serie', id: 'serie_number' },
                                { label: 'Emissao', id: 'created_at' },
                                { label: 'Valor', id: 'value' },
                            ]}
                            activePage={0}
                            totalPages={2}
                            data={filteredData}
                            customComponent={{
                                actions: (id) => (
                                    <Row>
                                        {renderTooltip(<TicketIcon
                                            onClick={(e) => {
                                                setSelectedId(id);
                                                setOpen(true)
                                                setAnchorEl(e.currentTarget);

                                            }}
                                        />, "Solicitar Nota Fiscal")}

                                    </Row>
                                ),
                                created_at: (e) => format(new Date(e), 'dd/MM/yyyy'),
                                value: (e) => e.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                            }}
                        />
                    </WrapperTable>
                </Paper>


                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setOpen(false);
                        setSelectedId(null);
                    }}
                    placement="top"
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <WrapperPopover>
                        <PopoverText>Confirma solicitação de 2ª via de Nota Fiscal ?</PopoverText>

                        <PopoverButton
                            onClick={() => {
                                setOpen(false);
                                receiveInvoices()
                            }}
                        >
                            Sim
                        </PopoverButton>
                    </WrapperPopover>
                </Popover>

            </Loading>
        </Container >
    )

}
import { string, func, array, bool, number } from 'prop-types';

import React from 'react';
import { DataGrid } from '@mui/x-data-grid'
import {
  WrapperGrid,
  useStyles,
} from './styles';

function Grid({
  columns,
  rows,
  pageSize,
  onRowSelected,
  selectionModel,
  checkboxSelection,
  hideFooterPagination,
  width,
  height
}) {

  const classes = useStyles();

  return (

    <WrapperGrid width={width} height={height}>
      <DataGrid
        className={classes.root}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowHeight={38}
        autoPageSize={true}
        hideFooterPagination={hideFooterPagination}
        onSelectionModelChange={onRowSelected}
        selectionModel={selectionModel}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
      />


    </WrapperGrid>

  );
}

export default Grid;


Grid.propTypes = {
  columns: array,
  rows: array,
  onRowSelected: func,
  selectionModel: array,
  checkboxSelection: bool,
  hideFooterPagination: bool,
  width: string,
  height: string,
  pageSize: number,
};

Grid.defaultProps = {
  columns: [],
  rows: [],
  onRowSelected: null,
  selectionModel: [],
  checkboxSelection: true,
  hideFooterPagination:true,
  width: '',
  height: '',
  pageSize:5
};




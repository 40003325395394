import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  `

export const Alert = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 15px;
    border-radius:5px;
    background-color: #ebebeb;

`

export const Button = styled.button`
  flex:1;
  text-align: center;
  vertical-align:center;
  min-height: 25px;
  min-width:25px;
  border: none;
  background-color: #122d58;
  color: #fff;
  padding:5px;
  border-radius: 3px;
  font-size: 12px;
  margin-top: 10px;
`

import React, { useState } from 'react';
import { string, func, bool, arrayOf } from 'prop-types';
import { Container, Label } from './styles';
import 'react-datepicker/dist/react-datepicker.css';
import { MultiSelect } from 'react-multi-select-component';

function MultiSelected({
  label,
  options,
  onChange,
  value,
  hasSelectAll,
  disableSearch
}) {
  

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        hasSelectAll={hasSelectAll}
        disableSearch={disableSearch}
      />
    </Container>
  );
}

export default MultiSelected;

MultiSelected.propTypes = {
  options: arrayOf,
  onChange: func,
  hasSelectAll: bool,
  disableSearch: bool,
  label: string,
  placeholder: string
};

MultiSelected.defaultProps = {
  options: [],
  onChange: () => { },
  hasSelectAll: false,
  disableSearch: false,
  label: '',
  placeholder: ''
};

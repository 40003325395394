import styled from 'styled-components';
import { MdDescription } from 'react-icons/md';
import { Button, withStyles } from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';

export const Container = styled.div`
`;

export const WrapperBody = styled.div`
  margin-top: 15px;
  background-color: #ebebeb;
`
export const ItemReport = styled(MdDescription).attrs({
  size: '25px',
})`
  color: #7b7b7b;
  cursor: pointer;

`;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: row;
  width:80%;
`

export const WrapperField = styled.div`
  flex:1;
  flex-direction: row;
  padding-top: 20px;
  padding-left: 40px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;

`
export const Collumn = styled.div`
  flex:1;
`
export const Header = styled.div`
  height: 50px;
  background-color: #145b99;
  display: flex;
  align-items: center;
  padding-left: 28px;

  color: #fff;
  font-size: 16px;
  font-weight: 700;
`

export const SendButton = withStyles({
  root: {
    marginBottom: 30,
    marginLeft: 30,
    color: '#122D58',
    fontSize: 13,
    borderRadius: 3,
    fontWeight: 700,
    marginTop: 60,
    backgroundColor: '#145b99',
    '&:hover': {
      backgroundColor: '#1666ab',
    },
  },
})(Button);

export const ClearButton = withStyles({
  root: {
    marginBottom: 30,
    marginLeft: 30,
    color: '#122D58',
    fontSize: 13,
    borderRadius: 3,
    fontWeight: 700,
    marginTop: 60,
    backgroundColor: '#FFF28D',
    boxShadow: '0px 3px 6px #00000029',
    '&:hover': {
      backgroundColor: '#fceb5d',
    },
  },
})(Button);


export const WrapperButtons = styled.div`
  display: flex;
  width:80%;
  margin-bottom: 30px;
  margin-left: 15px;

`;

export const ButtonText = styled.span`
  padding: 0 10px;
  color: #fff
`;

export const ButtonTextClear = styled.span`
  padding: 0 10px;
  color: #1666ab
`;


export const Loading = styled(LoadingOverlay).attrs({
  color: '#122d58',
  styles: {
    overlay: (base) => ({
      ...base,
      '& svg circle': {
        stroke: '#fff'
      },
      marginTop: '5%',
      height:'85%',
      color:'#fff',
      fontSize: 13,
      backgroundColor: '#122d58',
      opacity: 0.6,
      fontWeight: 600,
    }),
    fontWeight: 600
  }
})``;
import React from 'react';
import { element } from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../components/Modal';
import Images from 'assets/img';
import AuthActions from 'store/ducks/auth';

import {
  useStyles,
  Logo,
  ContainerProfile,
  PopoverRow,
  ProfileIcon,
  ExitIcon,
  PopoverText,
  Report,
  ItemReport,
  Home,
  Payment,
  Invoices,
  IconReceive,
  Configure,
  KeyIcon,
  Collumn,
  CatalogIcon
} from './styles';

export default function DefaultLayout({ children }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [updatePassword, setUpdatePassword] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [reportsEl, setReportsEl] = React.useState(null);
  const userActive = useSelector((state) => state.auth.userActive);
  const hasPermission = userActive.roles_access
  const [openReport, setOpenReport] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenReport(false)

  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function openProfileMenu() {
    handlePopoverClose();
    setUpdatePassword(false)
    setOpenProfile(true);
  }

  function openPasswordMenu() {
    handlePopoverClose();
    setUpdatePassword(true)
    setOpenProfile(true);
  }

  const openItemReports = () => {
    handleDrawerOpen()
    setOpenReport(!openReport)

  }

  // const handleCloseReports = () => {
  //   //dispatch(ReportsActions.setReportOldAllRequest());
  //   ///setReportsEl(null);
  // };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}

          >
            <MenuIcon />
          </IconButton>
          <Logo src={Images.Logo} />
          <ContainerProfile >

            <Configure onClick={handlePopoverOpen} />

            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <PopoverRow onClick={openProfileMenu}>
                <ProfileIcon />
                <PopoverText>Perfil</PopoverText>
              </PopoverRow>
              <PopoverRow onClick={openPasswordMenu}>
                <KeyIcon />
                <PopoverText>Alterar Senha</PopoverText>
              </PopoverRow>
              <PopoverRow onClick={() => dispatch(AuthActions.signOut())}>
                <ExitIcon />
                <PopoverText>Sair</PopoverText>
              </PopoverRow>
            </Popover>

            {/* <Popover
              anchorEl={reportsEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              onClose={handleCloseReports}
              open={Boolean(reportsEl)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >

            </Popover> */}
          </ContainerProfile>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>

          <ListItem button component={NavLink} to="/dashboard">
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Dashboard" style={{ fontWeight: 700, verticalAlign: 'center' }} />
          </ListItem>

          {hasPermission['tickets'] === "true" ?
            <ListItem button component={NavLink} to="/tickets">
              <ListItemIcon>
                <Payment />
              </ListItemIcon>
              <ListItemText primary="Boletos" style={{ fontWeight: 700, marginTop: 8 }} />
            </ListItem>
            : null}

          {hasPermission['invoices'] === "true" ?
            <ListItem button component={NavLink} to="/invoices">
              <ListItemIcon>
                <Invoices />
              </ListItemIcon>
              <ListItemText primary="Notas Fiscais" style={{ fontWeight: 700, marginTop: 8 }} />
            </ListItem>
            : null}

          {hasPermission['receivables'] === "true" ?
            <ListItem button component={NavLink} to="/receivables">
              <ListItemIcon>
                <IconReceive />
              </ListItemIcon>
              <ListItemText primary="Antecipação" style={{ fontWeight: 700, marginTop: 8 }} />
            </ListItem>
            : null}

          <ListItem button component={NavLink} to="/catalogs">
            <ListItemIcon>
              <CatalogIcon />
            </ListItemIcon>
            <ListItemText primary="Catálogo" style={{ fontWeight: 700, marginTop: 8 }} />
          </ListItem>


          {hasPermission['reports'] === "true" ?
            <>
              <ListItem button onClick={openItemReports} component={NavLink} to='/reports'>
                <ListItemIcon >
                  <Report />
                </ListItemIcon>
                <ListItemText primary="Relatórios" style={{ fontWeight: 700, marginTop: 8 }} />
              </ListItem>

              {openReport ?
                <Collumn>

                  <ListItem button component={NavLink} to='/reports/credit'>
                    <ListItemIcon style={{ marginLeft: 13 }} >
                      <ItemReport />
                      <ListItemText primary="Creditos" style={{ fontWeight: 700, marginLeft: 5, marginTop: "auto", marginBottom: "auto" }} />
                    </ListItemIcon>
                  </ListItem>

                  <ListItem button component={NavLink} to='/reports/creditCompensation'>
                    <ListItemIcon style={{ marginLeft: 13 }} >
                      <ItemReport />
                      <ListItemText primary="Credito x Compensação" style={{ fontWeight: 700, marginLeft: 5, marginTop: "auto", marginBottom: "auto" }} />
                    </ListItemIcon>
                  </ListItem>

                  <ListItem button component={NavLink} to='/reports/expiredReceivables'>
                    <ListItemIcon style={{ marginLeft: 13, marginTop: 10, }} >
                      <ItemReport />
                      <ListItemText primary="Titulos Vencidos" style={{ fontWeight: 700, marginLeft: 5, marginTop: "auto", marginBottom: "auto" }} />
                    </ListItemIcon>
                  </ListItem>

                  <ListItem button component={NavLink} to='/reports/winReceivables'>
                    <ListItemIcon style={{ marginLeft: 13, marginTop: 10, }} >
                      <ItemReport />
                      <ListItemText primary="Titulos a Vencer" style={{ fontWeight: 700, marginLeft: 5, marginTop: "auto", marginBottom: "auto" }} />
                    </ListItemIcon>
                  </ListItem>

                  <ListItem button component={NavLink} to='/reports/requests'>
                    <ListItemIcon style={{ marginLeft: 13, marginTop: 10, }} >
                      <ItemReport />
                      <ListItemText primary="Meus Pedidos" style={{ fontWeight: 700, marginLeft: 5, marginTop: "auto", marginBottom: "auto" }} />
                    </ListItemIcon>
                  </ListItem>



                </Collumn>
                : null}

            </>
            : null}

        </List>
        <Divider />
      </Drawer>

      <Modal
        open={openProfile}
        updatePassword={updatePassword}
        onClose={() => setOpenProfile(false)}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

DefaultLayout.propTypes = {
  children: element.isRequired,
};

import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getReportsCreditRequest: ['filter'],
  getReportsCreditSuccess: null,
  getReportsCreditFailure: ['error'],
  getReportsWinReceivablesRequest: ['filter'],
  getReportsWinReceivablesSuccess: null,
  getReportsWinReceivablesFailure: ['error'],
  getReportsCreditCompensationRequest: ['filter'],
  getReportsCreditCompensationSuccess: null,
  getReportsCreditCompensationFailure: ['error'],
  getReportsRequestsRequest: ['filter'],
  getReportsRequestsSuccess: null,
  getReportsRequestsFailure: ['error'],
});

export const ReportsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  filter: null,
  report: null,
  isLoading: false,
  error: null
});

/* Reducers */

const getReportsCreditRequest = (state, { filter }) =>
  state.merge({
    isLoading: true,
    filter
  });

const getReportsCreditSuccess = (state) =>
  state.merge({
    isLoading: false
  });

const getReportsCreditFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });


const getReportsWinReceivablesRequest = (state, { filter }) =>
  state.merge({
    isLoading: true,
    filter
  });

const getReportsWinReceivablesSuccess = (state) =>
  state.merge({
    isLoading: false
  });

const getReportsWinReceivablesFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const getReportsCreditCompensationRequest = (state, { filter }) =>
  state.merge({
    isLoading: true,
    filter
  });

const getReportsCreditCompensationSuccess = (state) =>
  state.merge({
    isLoading: false
  });

const getReportsCreditCompensationFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

  const getReportsRequestsRequest = (state, { filter }) =>
  state.merge({
    isLoading: true,
    filter
  });

const getReportsRequestsSuccess = (state) =>
  state.merge({
    isLoading: false
  });

const getReportsRequestsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_REPORTS_CREDIT_REQUEST]: getReportsCreditRequest,
  [Types.GET_REPORTS_CREDIT_SUCCESS]: getReportsCreditSuccess,
  [Types.GET_REPORTS_CREDIT_FAILURE]: getReportsCreditFailure,
  [Types.GET_REPORTS_WIN_RECEIVABLES_REQUEST]: getReportsWinReceivablesRequest,
  [Types.GET_REPORTS_WIN_RECEIVABLES_SUCCESS]: getReportsWinReceivablesSuccess,
  [Types.GET_REPORTS_WIN_RECEIVABLES_FAILURE]: getReportsWinReceivablesFailure,
  [Types.GET_REPORTS_CREDIT_COMPENSATION_REQUEST]: getReportsCreditCompensationRequest,
  [Types.GET_REPORTS_CREDIT_COMPENSATION_SUCCESS]: getReportsCreditCompensationSuccess,
  [Types.GET_REPORTS_CREDIT_COMPENSATION_FAILURE]: getReportsCreditCompensationFailure,
  [Types.GET_REPORTS_REQUESTS_REQUEST]: getReportsRequestsRequest,
  [Types.GET_REPORTS_REQUESTS_SUCCESS]: getReportsRequestsSuccess,
  [Types.GET_REPORTS_REQUESTS_FAILURE]: getReportsRequestsFailure

});

import { put, select } from 'redux-saga/effects';
import api from 'services/api'
import DashboardActions from 'store/ducks/dashboard';
import { actions as toastrActions } from 'react-redux-toastr';
import localStorage from 'local-storage';

//-------------------------------------------------
//Busca dados para montar dashboard
//-------------------------------------------------
export function* getDashboard({dataFilter}) {

  const sectionActive = localStorage.get("section")
  const userActive = yield select((state) => state.auth.userActive);
  const company  = userActive.roles_access.company
  const cpf      = userActive.cpf

  try {

    const { token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("dashboard", { company,cpf,dataFilter })
    const dashboard = response.data

    //valido retorno
    if (response.status === 200) {

      yield put(DashboardActions.getDashboardSuccess(dashboard))

    }

  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao atualizar Dashboard',
        message: 'Tente novamente',
      })
    );

    yield put(DashboardActions.getDashboardFailure(err));

  }
}


import 'normalize.css';

import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './config/ReactrotronConfig';
import { ptBR } from '@material-ui/core/locale';

import Routes from './routes';
import store from './store';
import GlobalStyle from './styles/global';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';

import { Alert, Button, Container } from './styles';
import { Row } from 'styles/components';

const theme = createMuiTheme(
  {
    typography: {
      fontFamily: ['Montserrat'].join(','),
    },
  },
  ptBR
);

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '120px',
  transition: transitions.SCALE,
  onClose: () => { },
}

const AlertTemplate = ({ style, options, message, close }) => (
  <Container>
    <Alert style={style}>
      <Row>
        {options.type === 'info'}
        {options.type === 'success' && ':)'}
        {options.type === 'error' && ':('}
        {message}
      </Row>
      <Row>
        <Button onClick={close}>Fechar </Button>
      </Row>
    </Alert>
  </Container>
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Routes />
          <GlobalStyle />
          <ReduxToastr
            closeOnToastrClick
            position="top-right"
            preventDuplicates
            progressBar
            timeOut={4000}
          />
        </AlertProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
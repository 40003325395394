import styled from 'styled-components';
import { MdArrowBack } from 'react-icons/md';

export const Container = styled.div``;

export const IconBack = styled(MdArrowBack)`
  color: ${(props) => (props.disabled ? '#AAA' : '#2DB7F8')};
  height: 18px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  transform: translateY(2px);
`;

export const IconForward = styled(MdArrowBack)`
  color: ${(props) => (props.disabled ? '#AAA' : '#2DB7F8')};
  height: 18px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  transform: rotate(180deg) translateY(-2px);
`;

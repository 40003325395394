import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getDashboardRequest: ['dataFilter'],
  getDashboardSuccess: ['dashboard'],
  getDashboardFailure: ['error'],
});

export const DashboardTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  dashboard: [],
  dataFilter: {},
  isLoading: false,
  error: null
});

/* Reducers */

const getDashboardRequest = (state ,{ dataFilter }) =>
  state.merge({
    isLoading: true,
    dataFilter
  });

const getDashboardSuccess = (state, { dashboard }) =>
state.merge({
  dashboard,
  isLoading: false,
});
 
const getDashboardFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });


export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DASHBOARD_REQUEST]: getDashboardRequest,
  [Types.GET_DASHBOARD_SUCCESS]: getDashboardSuccess,
  [Types.GET_DASHBOARD_FAILURE]: getDashboardFailure,

});

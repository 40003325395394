import styled from 'styled-components';
import { MdRemoveRedEye } from 'react-icons/md';
import { FaFileInvoice, FaReceipt } from 'react-icons/fa';
import LoadingOverlay from 'react-loading-overlay';

export const Container = styled.div``;

export const WrapperTable = styled.div`
  padding: 20px 0;
`;

export const WrapperAdd = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
`;

export const Loading = styled(LoadingOverlay).attrs({
  color: '#122d58',
  styles: {
    overlay: (base) => ({
      ...base,
      '& svg circle': {
        stroke: '#fff'
      },
      marginTop: '8%',
      height:'80%',
      color:'#fff',
      fontSize: 13,
      backgroundColor: '#122d58',
      opacity: 0.6,
      fontWeight: 600,
    }),
    fontWeight: 600
  }
})``;

export const EyeIcon = styled(MdRemoveRedEye).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 26px;
`;

export const TicketIcon = styled(FaReceipt).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 26px;
`;

export const InvoicesIcon = styled(FaFileInvoice).attrs({
  size: '20px',
})`
  color: #7b7b7b;
  cursor: pointer;

`;

export const WrapperPopover = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
`;


export const PopoverText = styled.p`
  color: #888;
  margin-right: 10px;
`;

export const PopoverButton = styled.div`
  padding: 3px 5px;
  background-color: #f27160;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
`;

import { put, select } from 'redux-saga/effects';
import api from 'services/api'
import ReportsActions from 'store/ducks/reports';
import { actions as toastrActions } from 'react-redux-toastr';
import localStorage from 'local-storage';

export function* getReportCredit({filter}) {

  const sectionActive = localStorage.get("section")

  try {

    const { email, token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("reports/credit", { filter,email})

    //valido retorno
    if (response.status === 200) {

      yield put(ReportsActions.getReportsCreditSuccess())

      yield put(
        toastrActions.add({
          type: 'success',
          title: 'Credito',
          message: 'Relatório solicitado com sucesso!',
        })
      );

    }

  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao solicitar relatório',
        message: 'Tente novamente',
      })
    );

    yield put(ReportsActions.getReportsCreditFailure(err));

  }
}



export function* getReportWinReceivables({filter}) {

  const sectionActive = localStorage.get("section")

  try {

    const { email, token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("reports/winReceivables", { filter,email})

    //valido retorno
    if (response.status === 200) {

      yield put(ReportsActions.getReportsWinReceivablesSuccess())

      yield put(
        toastrActions.add({
          type: 'success',
          title: 'Titulo Vencer',
          message: 'Relatório solicitado com sucesso!',
        })
      );

    }

  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao solicitar relatório',
        message: 'Tente novamente',
      })
    );

    yield put(ReportsActions.getReportsWinReceivablesFailure(err));

  }
}



export function* getReportsCreditCompensation({filter}) {

  const sectionActive = localStorage.get("section")

  try {

    const { email, token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("reports/creditCompensation", { filter,email})

    //valido retorno
    if (response.status === 200) {

      yield put(ReportsActions.getReportsCreditCompensationSuccess())

      yield put(
        toastrActions.add({
          type: 'success',
          title: 'Crédito x Compensação',
          message: 'Relatório solicitado com sucesso!',
        })
      );

    }

  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao solicitar relatório',
        message: 'Tente novamente',
      })
    );

    yield put(ReportsActions.getReportsCreditCompensationFailure(err));

  }
}



export function* getRequests({filter}) {

  const sectionActive = localStorage.get("section")

  try {

    const { email, token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("reports/requests", { filter,email})

    //valido retorno
    if (response.status === 200) {

      yield put(ReportsActions.getReportsRequestsSuccess())

      yield put(
        toastrActions.add({
          type: 'success',
          title: 'Pedidos',
          message: 'Relatório solicitado com sucesso!',
        })
      );

    }

  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao solicitar relatório',
        message: 'Tente novamente',
      })
    );

    yield put(ReportsActions.getReportsRequestsFailure(err));

  }
}






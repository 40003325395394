import React from 'react';
import { number, func, string } from 'prop-types';
import Paginate from 'react-paginate';

import stylesCSS from './styles.module.css';
import { Container, IconBack, IconForward } from './styles';

export default function PageNavigator({ actual, total, height, onChange }) {
  return (
    <Container height={height}>
      <Paginate
        pageCount={total}
        pageRangeDisplayed={4}
        marginPagesDisplayed={2}
        previousLabel={<IconBack disabled={actual === 0} />}
        nextLabel={<IconForward disabled={actual === total - 1} />}
        containerClassName={stylesCSS.container}
        pageClassName={stylesCSS.page}
        activeClassName={stylesCSS.pageActive}
        previousClassName={stylesCSS.previous}
        previousLinkClassName={stylesCSS.link}
        nextClassName={stylesCSS.next}
        nextLinkClassName={stylesCSS.link}
        breakClassName={stylesCSS.break}
        pageLinkClassName={stylesCSS.link}
        forcePage={actual}
        onPageChange={onChange}
      />
    </Container>
  );
}

PageNavigator.propTypes = {
  actual: number.isRequired,
  total: number.isRequired,
  onChange: func.isRequired,
  height: string,
};

PageNavigator.defaultProps = {
  height: null,
};

/*  Exemplo de Uso

    <PageNavigator
      actual={4}
      total={10}
      onChange={page => setActivePage(page.selected)}
    />

*/

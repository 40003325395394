import React from 'react';
import { string, element, bool, oneOfType } from 'prop-types';

// import { isNull } from 'lodash';
import { Icon, Column } from 'styles/components';

import { Container, Header, WrapperLeft, Title, Subtitle } from './styles';

function Paper({
  title,
  subtitle,
  icon,
  children,
  rightContent,
  noHeader,
  minHeight,
}) {
  return (
    <Container minHeight={minHeight}>
      {!noHeader && (
        <Header>
          <WrapperLeft subtitle={subtitle}>
            {typeof icon === 'string' ? (
              <Icon src={icon} height="24px" margin="0 25px 0 0" />
            ) : (
              icon
            )}
            <Column>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              <Title>{title.toUpperCase()}</Title>
            </Column>
          </WrapperLeft>
          {rightContent}
        </Header>
      )}
      {children}
    </Container>
  );
}

export default Paper;

Paper.propTypes = {
  title: string,
  subtitle: string,
  icon: oneOfType({
    string,
    element,
  }),
  children: element,
  rightContent: element,
  noHeader: bool,
  minHeight: string,
};

Paper.defaultProps = {
  title: '',
  subtitle: '',
  icon: null,
  children: null,
  rightContent: null,
  noHeader: false,
  minHeight: null,
};

import React from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import history from '../services/history';
import Route from './Route';

import SignIn from '../pages/signin';
import Dashboard from '../pages/Dashboard';
import Credit from '../pages/Reports/components/Credit';
import CreditCompensation from '../pages/Reports/components/CreditCompensation';
import { useSelector } from 'react-redux';
import Ticket from '../pages/Ticket';
import Invoices from '../pages/Invoices';
import Receivables from '../pages/Receivables';
import Forgot from '../pages/Forgot';
import CreateReceivables from '../pages/Receivables/CreateReceivables';
import Reports from '../pages/Reports';
import ExpiredReceivables from '../pages/Reports/components/ExpiredReceivables';
import WinReceivables from '../pages/Reports/components/WinReceivables';
import Requests from '../pages/Reports/components/Requests';
import Catalogs from '../pages/Catalogs';

function NoMatch() {
  const location = useLocation();

  return (
    <div>
      <h3>
        Página não encontrada - No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default function Routes() {

  const userActive = useSelector((state) => state.auth.userActive);
  const hasPermission = userActive ? userActive.roles_access : {}

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Redirect exact path="/" to="/signin" />
        <Route path="/signin" exact component={SignIn} />
        <Route path="/forgot"    component={Forgot} />
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/catalogs" component={Catalogs} isPrivate /> 

        { hasPermission['tickets']  ? <Route path="/tickets"  component={Ticket}   isPrivate /> : <Redirect to="/" /> }
        { hasPermission['invoices'] ? <Route path="/invoices" component={Invoices} isPrivate /> : <Redirect to="/" /> }
        { hasPermission['receivables']  ? <Route path="/receivables"  component={Receivables}  isPrivate /> : <Redirect to="/" /> }
        { hasPermission['receivables']  ? <Route path="/createReceivables"  component={CreateReceivables}  isPrivate /> : <Redirect to="/" /> }
        { hasPermission['reports']  ? <Route path="/reports/creditCompensation"  component={CreditCompensation}  isPrivate /> : <Redirect to="/" /> }
        { hasPermission['reports']  ? <Route path="/reports/credit"  component={Credit}  isPrivate /> : <Redirect to="/" /> }
        { hasPermission['reports']  ? <Route path="/reports/expiredReceivables"  component={ExpiredReceivables}  isPrivate /> : <Redirect to="/" /> }
        { hasPermission['reports']  ? <Route path="/reports/winReceivables"  component={WinReceivables}  isPrivate /> : <Redirect to="/" /> }
        { hasPermission['reports']  ? <Route path="/reports/requests"  component={Requests}  isPrivate /> : <Redirect to="/" /> }
        { hasPermission['reports']  ? <Route path="/reports"  component={Reports}  isPrivate /> : <Redirect to="/" /> }

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </ConnectedRouter>
  );
}

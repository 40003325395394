import Banner from './banner.png'
import Logo from './logo.png'
import Profile from './icon-profile-img.png'
const Images = {
  Banner,
  Logo,
  Profile
};

export default Images;

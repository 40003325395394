import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getTicketsRequest: null,
  getTicketsSuccess: ['tickets'],
  getTicketsFailure: ['error'],
  receiveTicketsRequest:  ['tickets'],
  receiveTicketsSuccess:  null,
  receiveTicketsFailure: ['error'],
});

export const TicketsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  tickets: [],
  isLoading: false,
  error: null
});

/* Reducers */

const getTicketsRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getTicketsSuccess = (state, { tickets }) =>
state.merge({
  tickets,
  isLoading: false,
});
 
const getTicketsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

  const receiveTicketsRequest = (state,{ ticket }) =>
  state.merge({
    ticket,
    isLoading: true,
  });
  
const receiveTicketsSuccess = (state) =>
state.merge({
  isLoading: false,
});
 
const receiveTicketsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TICKETS_REQUEST]: getTicketsRequest,
  [Types.GET_TICKETS_SUCCESS]: getTicketsSuccess,
  [Types.GET_TICKETS_FAILURE]: getTicketsFailure,
  [Types.RECEIVE_TICKETS_REQUEST]: receiveTicketsRequest,
  [Types.RECEIVE_TICKETS_SUCCESS]: receiveTicketsSuccess,
  [Types.RECEIVE_TICKETS_FAILURE]: receiveTicketsFailure,

});

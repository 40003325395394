import styled from 'styled-components';
import {  MdDescription, MdLibraryBooks, MdRemoveRedEye } from 'react-icons/md';
import LoadingOverlay from 'react-loading-overlay';
import { GiMoneyStack } from 'react-icons/gi';

export const Container = styled.div``;

export const WrapperTable = styled.div`
  padding: 20px 0;
`;

export const Loading = styled(LoadingOverlay).attrs({
  color: '#122d58',
  styles: {
    overlay: (base) => ({
      ...base,
      '& svg circle': {
        stroke: '#fff'
      },
      marginTop: '8%',
      height:'80%',
      color:'#fff',
      fontSize: 13,
      backgroundColor: '#122d58',
      opacity: 0.6,
      fontWeight: 600,
    }),
    fontWeight: 600
  }
})``;

export const IconReceive = styled(MdLibraryBooks).attrs({
  size: '28px',
})`
  color: #7b7b7b;
  cursor: pointer;
  vertical-align:'center';
`;
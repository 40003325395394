import { put, select } from 'redux-saga/effects';
import api from 'services/api';
import ReceivablesActions from 'store/ducks/receivables';
import localStorage from 'local-storage';
import { actions as toastrActions } from 'react-redux-toastr';

export function* getReceivables() {

    const sectionActive = localStorage.get("section")
    const userActive = yield select((state) => state.auth.userActive);
    const company = userActive.roles_access.company
    const cpf = userActive.cpf

    try {

        const { token } = sectionActive

        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        api.defaults.headers.post['Content-Type'] = 'application/json';

        const response = yield api.post("receivables", { company, cpf })
        const { data } = response

        const receivables = data.receivables.map((item) => ({ ...item }))

        if (response.status === 200) {

            yield put(ReceivablesActions.getReceivablesSuccess(receivables))

        }

    } catch (error) {

        yield put(
            toastrActions.add({
                type: 'error',
                title: 'Falha ao buscar antecipação',
                message: 'Tente novamente',
            })
        );

        yield put(ReceivablesActions.getReceivablesFailure(error));


    }
}


export function* getItemsReceivables() {

    const sectionActive = localStorage.get("section")
    const userActive = yield select((state) => state.auth.userActive);
    const company = userActive.roles_access.company
    const cpf = userActive.cpf

    try {

        const { token } = sectionActive

        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        api.defaults.headers.post['Content-Type'] = 'application/json';

        const response = yield api.post("receivables/items", { company, cpf })
        const { data } = response

        const receivables = data.receivables.map((item) => ({ ...item }))

        if (response.status === 200) {

            yield put(ReceivablesActions.itemsReceivablesSuccess(receivables))

        }

    } catch (error) {

        yield put(
            toastrActions.add({
                type: 'error',
                title: 'Falha ao buscar antecipação',
                message: 'Tente novamente',
            })
        );

        yield put(ReceivablesActions.itemsReceivablesFailure(error));


    }

}


export function* setReceivables({receivable,onClose}) {

    const sectionActive = localStorage.get("section")
    const userActive = yield select((state) => state.auth.userActive);
    const cpf = userActive.cpf

    try {


        const { token } = sectionActive

        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        api.defaults.headers.post['Content-Type'] = 'application/json';

        const response = yield api.post("receivables/create", { receivable, cpf })
        const { data } = response

        if (response.status){
            yield put(ReceivablesActions.setReceivablesSuccess())
            onClose()
        } else {
            throw data.error
        }

    } catch (error) {

        yield put(
            toastrActions.add({
                type: 'error',
                title: 'Falha ao gravar antecipação',
                message: 'Tente novamente',
            })
        );

        yield put(ReceivablesActions.setReceivablesFailure(error));

    }

}
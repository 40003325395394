import { put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';

import api from 'services/api';
import localStorage from 'local-storage';
import UsersActions from 'store/ducks/users';
import AuthActions from '../ducks/auth';

export function* getUserActive() {

  try {

    const sectionActive = localStorage.get("section")
    const { email, token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("users/getuser/", { email })

    //valido o token
    if (response.status === 200) {
      yield put(AuthActions.signInSuccess(response.data));

    }

  } catch (error) {
     console.log("failed updated userActive")
  }
}


export function* updateUser({ userActive, closeModal }) {

  try {

    const sectionActive = localStorage.get("section")
    const { token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    yield api.post("users/updateUser/", { userActive })

    yield put(UsersActions.updateUserSuccess(userActive));
    yield put(UsersActions.getUserRequest() )

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'PERFIL',
        message: 'Atualizado com sucesso!',
      })
    );
    closeModal();
  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao atualizar o perfil',
        message: 'Tente novamente',
      })
    );

    yield put(UsersActions.updateUserFailure(err));
  }
}


export function* updateUserPassword({ password, closeModal }) {

  try {

    const sectionActive = localStorage.get("section")
    const { token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    yield api.post("users/updateUserPassword/", {  password })

    yield put(UsersActions.updatePasswordSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'SENHA',
        message: 'Alterada com sucesso!',
      })
    );
    closeModal();
  } catch (err) {

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao alterar a senha',
        message: 'Tente novamente',
      })
    );

    yield put(UsersActions.updatePasswordFailure(err));
  }
}

/* eslint-disable no-nested-ternary */
import React from 'react';
import { bool, func, arrayOf, object } from 'prop-types';
import Modal from 'components/Modal';
import { format } from 'date-fns';

import {
    Container,
    WrapperBody,
    WrapperContent,
    WrapperBottom,
    BottomText,
    TextDescription,
    Text,
    WrapperTable,
} from './styles';

function ModalComp({
    data,
    open,
    selectedId,
    onClose,
}) {

    const datafilter = data.filter((item) => item.branch + item.number + item.portion === selectedId)
    const [ticket] = datafilter
    return (ticket ?

        <Modal open={open} onClose={onClose} title={"Detalhes do Boleto"}>
            <Container>
                <WrapperBody>

                    <WrapperTable>
                        <WrapperContent>
                            <TextDescription>Empresa</TextDescription>
                            <Text>{ticket.cnpj}</Text>
                        </WrapperContent>
                    </WrapperTable>

                    <WrapperTable>
                        <WrapperContent>
                            <TextDescription>Razão Social</TextDescription>
                            <Text>{ticket.social_reason}</Text>
                        </WrapperContent>
                    </WrapperTable>

                    <WrapperTable>
                        <WrapperContent>
                            <TextDescription>Numero</TextDescription>
                            <Text>{ticket.number}</Text>
                        </WrapperContent>

                        <WrapperContent>
                            <TextDescription>Parcela</TextDescription>
                            <Text>{ticket.portion}</Text>
                        </WrapperContent>

                        <WrapperContent>
                            <TextDescription>Emissao</TextDescription>
                            <Text>{format(ticket.created_at, 'dd/MM/yyyy')}</Text>
                        </WrapperContent>
                        <WrapperContent>
                            <TextDescription>Vencimento</TextDescription>
                            <Text>{format(ticket.due_date, 'dd/MM/yyyy')}</Text>
                        </WrapperContent>

                    </WrapperTable>

                    <WrapperTable style={{ justifyContent: "flex-end"}}>

                        <WrapperContent>
                            <TextDescription style={{ marginLeft:'auto',fontSize: 16 }}>Valor</TextDescription>
                            <Text style={{ fontSize: 18,fontWeight: 600 }}>{ticket.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                        </WrapperContent>

                    </WrapperTable>
                </WrapperBody>

                <WrapperBottom>
                    <BottomText onClick={onClose}>Cancelar</BottomText>
                </WrapperBottom>
            </Container>

        </Modal >
        : null)
}

export default ModalComp;

ModalComp.propTypes = {
    open: bool.isRequired,
    onClose: func.isRequired,
    data: arrayOf(object).isRequired,
};
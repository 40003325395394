import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as toastr } from 'react-redux-toastr';
import { reducer as auth } from './auth';
import { reducer as tickets } from './tickets';
import { reducer as invoices } from './invoices';
import { reducer as dashboard } from './dashboard';
import { reducer as receivables } from './receivables';
import { reducer as reports } from './reports';

const duckReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    toastr,
    auth,
    tickets,
    invoices,
    dashboard,
    receivables,
    reports
  });

  export default duckReducer
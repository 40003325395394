import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({

  root: {

    '& .MuiDataGrid-columnHeader': {
      height: 30,
      fontSize: 12,
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none'
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none'
    },

    '& .MuiDataGrid-row': {
      height: 30,
      fontSize: 12,
    },
    '& .MuiCheckbox-root svg': {
      width: 18,
      height: 18,
      backgroundColor: 'transparent',
      borderRadius: 2,
      color:'#122d58'
    },
    '& .MuiDataGrid-selectedRowCount':{
      fontSize:12,
    }


  },

}));


export const WrapperGrid = styled.div`
  height: ${(props) => props.height ? props.height : '350px'};
  width: ${(props) => props.width ? props.width : '100%'};
  min-height:250px;
`

import React from 'react';
import { string, arrayOf, object, func, bool } from 'prop-types';

import { Container, Label, Content,Column } from './styles';

export default function Selector({
  name,
  options,
  onChange,
  value,
  type,
  label,
  width,
  margin,
  defaulOption,
  error,
  styles
}) {
  return (
    <Container width={width} margin={margin}>
      <Column>
        {label && <Label>{label}</Label>}
        <Content
          style={styles}
          name={name}
          onChange={onChange}
          value={value}
          type={type}
          error={error}
        >
          {defaulOption && (
            <option disabled selected value>
              {defaulOption}
            </option>
          )}
          {options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.text}
            </option>
          ))}
        </Content>
      </Column>
    </Container>
  );
}

Selector.propTypes = {
  name: string,
  options: arrayOf(object).isRequired,
  onChange: func.isRequired,
  value: string,
  type: string,
  label: string,
  width: string,
  margin: string,
  defaulOption: string,
  error: bool,
  style: string
};

Selector.defaultProps = {
  name: null,
  value: null,
  type: 'primary',
  label: null,
  width: null,
  margin: null,
  defaulOption: null,
  error: false,
  style: null
};

import { all, takeLatest, fork } from 'redux-saga/effects';

import { loginStatusWatcher, signIn, signOut, forgotPassword,resetPassword } from './auth';
import { AuthTypes } from '../ducks/auth';
import { UsersTypes } from '../ducks/users';
import { updateUser,getUserActive,updateUserPassword } from './users';
import { getTickets,receiveTickets } from './tickets';
import { TicketsTypes } from '../ducks/tickets';
import { getInvoices,receiveInvoices } from './invoices';
import { InvoicesTypes } from '../ducks/invoices'
import { DashboardTypes } from '../ducks/dashboard';
import { getDashboard } from './dashboard';
import { ReceivablesTypes } from '../ducks/receivables';
import { getItemsReceivables, getReceivables,setReceivables } from './receivables';
import { ReportsTypes } from '../ducks/reports';
import { getReportCredit, getReportsCreditCompensation, getReportWinReceivables, getRequests } from './reports';

export default function* rootSaga() {
  yield fork(loginStatusWatcher);
  yield all([
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest(AuthTypes.SIGN_OUT, signOut),
    takeLatest(AuthTypes.FORGOT_REQUEST, forgotPassword),

    takeLatest(UsersTypes.GET_USER_REQUEST, getUserActive),
    takeLatest(UsersTypes.UPDATE_USER_REQUEST, updateUser),
    takeLatest(UsersTypes.UPDATE_PASSWORD_REQUEST, updateUserPassword),

    takeLatest(TicketsTypes.GET_TICKETS_REQUEST, getTickets),
    takeLatest(TicketsTypes.RECEIVE_TICKETS_REQUEST, receiveTickets),
    
    takeLatest(InvoicesTypes.GET_INVOICES_REQUEST, getInvoices),
    takeLatest(InvoicesTypes.RECEIVE_INVOICES_REQUEST, receiveInvoices),

    takeLatest(DashboardTypes.GET_DASHBOARD_REQUEST, getDashboard),

    takeLatest(ReceivablesTypes.GET_RECEIVABLES_REQUEST, getReceivables),
    takeLatest(ReceivablesTypes.ITEMS_RECEIVABLES_REQUEST, getItemsReceivables),
    takeLatest(ReceivablesTypes.SET_RECEIVABLES_REQUEST, setReceivables),

    takeLatest(ReportsTypes.GET_REPORTS_CREDIT_REQUEST, getReportCredit),
    takeLatest(ReportsTypes.GET_REPORTS_WIN_RECEIVABLES_REQUEST, getReportWinReceivables),
    takeLatest(ReportsTypes.GET_REPORTS_CREDIT_COMPENSATION_REQUEST, getReportsCreditCompensation),
    takeLatest(ReportsTypes.GET_REPORTS_REQUESTS_REQUEST, getRequests),


  ]);
}

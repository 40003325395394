import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Button, Link } from '@material-ui/core';

export const Logo = styled.img`
  width: 25rem;
  height: 5rem;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  width: 90%;
  max-width: 273px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EnterButton = withStyles({
  root: {
    color: '#fff',
    fontSize: 13,
    borderRadius: 3,
    fontWeight: 700,
    marginTop: 60,
    backgroundColor: '#145b99',
    '&:hover': {
      backgroundColor: '#1666ab',
    },
  },
})(Button);

export const ButtonText = styled.span`
  padding: 0 10px;
`;

export const ForgotLink = withStyles({
  root: {
    color: '#fff',
  },
})(Link);

export const Forgot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GridLoading = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

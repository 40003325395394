import React, { useEffect, useState } from 'react';
import Paper from 'components/Paper';
import TextInput from 'components/TextInput';
import Selector from 'components/Selector';
import { schema } from './schemaValidation';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import ReportsActions from 'store/ducks/reports';

import {
  Container,
  Header,
  ItemReport,
  WrapperInput,
  WrapperField,
  Collumn,
  WrapperBody,
  SendButton,
  ClearButton,
  WrapperButtons,
  ButtonText,
  ButtonTextClear,
  Loading,
} from './styles';
import { useSelector } from 'react-redux';

export default function ExpiredReceivables() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reports.isLoading);
  const userActive = useSelector((state) => state.auth.userActive);
  const dataBranches = userActive.roles_access?.company?.branches.map(
    (item) => ({ text: item[0][1], value: item[0][0] })
  );

  const dataCompany = [{ text: 'Selecione', value: '' }, ...dataBranches];

  const group = userActive.roles_access?.company?.group.map((item) => ({
    text: item[0][1],
    value: item[0][0],
  }));

  const dataGroup = [{ text: 'Selecione', value: '' }, ...group];

  function handleSubmitted(values) {
    const filter = {
      ...values,
      emission_at: values.emission_at.replace('-', '').replace('-', ''),
      emission_end: values.emission_end.replace('-', '').replace('-', ''),
      expired_at: values.expired_at.replace('-', '').replace('-', ''),
      expired_end: values.emission_end.replace('-', '').replace('-', ''),
    };
    dispatch(ReportsActions.getReportsWinReceivablesRequest(filter));
  }

  return (
    <Container>
      <Paper title="Titulos Vencidos" icon={<ItemReport />} />
      <Loading active={isLoading} spinner text="Processando...">
        <Header>FILTROS</Header>
        <Formik
          enableReinitialize
          initialValues={{
            emission_at: '',
            emission_end: '',
            expired_at: '',
            expired_end: '',
            company: '',
            group: '',
            type: '2', // titulos a vencer
          }}
          onSubmit={(values) => handleSubmitted(values)}
          validationSchema={schema()}
        >
          {({
            values,
            handleChange,
            errors,
            touched,
            handleSubmit,
            resetForm,
          }) => (
            <WrapperBody>
              <WrapperInput>
                <WrapperField>
                  <TextInput
                    type="date"
                    label="Emissão de "
                    placeholder="Emissão"
                    name="emission_at"
                    value={values.emission_at}
                    onChange={handleChange}
                    error={touched.emission_at && Boolean(errors.emission_at)}
                  />
                </WrapperField>
                <WrapperField>
                  <TextInput
                    type="date"
                    label="Emissão Até "
                    placeholder="Emissão"
                    name="emission_end"
                    value={values.emission_end}
                    onChange={handleChange}
                    error={touched.emission_end && Boolean(errors.emission_end)}
                  />
                </WrapperField>
              </WrapperInput>

              <WrapperInput>
                <WrapperField>
                  <TextInput
                    type="date"
                    label="Vencimento de "
                    placeholder="Vencimento de"
                    name="expired_at"
                    value={values.expired_at}
                    onChange={handleChange}
                    error={touched.expired_at && Boolean(errors.expired_at)}
                  />
                </WrapperField>
                <WrapperField>
                  <TextInput
                    type="date"
                    label="Vencimento Até"
                    placeholder="Vencimento Até"
                    name="expired_end"
                    value={values.expired_end}
                    onChange={handleChange}
                    error={touched.expired_end && Boolean(errors.expired_end)}
                  />
                </WrapperField>
              </WrapperInput>

              <WrapperInput>
                <Collumn>
                  <WrapperField>
                    <Selector
                      label="Empresa"
                      options={dataCompany}
                      type="secondary"
                      name="company"
                      value={values.company}
                      onChange={handleChange}
                      width={'100%'}
                      error={touched.company && Boolean(errors.company)}
                    />
                  </WrapperField>
                </Collumn>

                <Collumn>
                  <WrapperField>
                    <Selector
                      label="Grupo"
                      options={dataGroup}
                      type="secondary"
                      name="group"
                      value={values.group}
                      onChange={handleChange}
                      width={'100%'}
                      error={touched.group && Boolean(errors.group)}
                    />
                  </WrapperField>
                </Collumn>
              </WrapperInput>

              <WrapperButtons>
                <SendButton onClick={handleSubmit}>
                  <ButtonText>ENVIAR</ButtonText>
                </SendButton>

                <ClearButton onClick={resetForm}>
                  <ButtonTextClear>Limpar</ButtonTextClear>
                </ClearButton>
              </WrapperButtons>
            </WrapperBody>
          )}
        </Formik>
      </Loading>
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  box-shadow: -3px 3px 6px #0000000f;
  border-radius: 5px;
  min-height: ${(props) => props.minHeight};
  transition: 200ms;
`;

export const Header = styled.div`
  padding: 42px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 510px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const WrapperLeft = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 510px) {
    padding-bottom: 20px;
  }
`;

export const Title = styled.span`
  font-size: 28px;
  font-weight: 700;
  color: #7b7b7b;
`;

export const Subtitle = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #7b7b7b;
  position: absolute;
  top: -15px;
`;

import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { actions as toastrActions } from 'react-redux-toastr';
import api from 'services/api';

import AuthActions from '../ducks/auth';
import localStorage from 'local-storage';

export function* loginStatusWatcher() {

  try {

    const sectionActive = localStorage.get("section")

    if (sectionActive == null) {

      yield put(AuthActions.authCheckedSuccess());
      return yield put(push('/signin'));

    }

    const { email, token } = sectionActive

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("users/getuser/", { email })

    //valido o token
    if (response.status === 200) {

      yield put(AuthActions.signInSuccess(response.data));
      yield put(AuthActions.authCheckedSuccess());

      yield put(push('/dashboard'));
    } else {

      yield put(AuthActions.signOut());

    }
  }

  catch (error) {

    //Token invalido
      yield put(AuthActions.signOut());

  }
}

export function* signIn({ email, password }) {

  try {

    const response = yield api.post("users/authenticate/", { email, password })
    const user = response.data

    localStorage.set("section", {
      "token": user.token,
      "email": email
    })

    yield put(AuthActions.signInSuccess(user.userActive));
    yield put(push('/dashboard'));

  }
  catch (error) {
    yield put(AuthActions.signInFailure(error));
    yield put(
      toastrActions.add({
        type: 'info',
        title: 'Falha no login',
        message: 'Verifique seu e-mail/senha!',
      })
    );

  }

}

export function* signOut() {

  try {

    localStorage.remove("section")
    yield put(push('/signin'));

  }
  catch (error) {
    console.error(error)
  }
}

export function* forgotPassword({ email }) {

  try {

    api.defaults.headers.post['Content-Type'] = 'application/json';

    const response = yield api.post("users/forgot/", { email })

    //valido o token
    if (response.status === 200) {

      yield put(AuthActions.forgotSuccess());

      yield put(
        toastrActions.add({
          type: 'success',
          title: 'E-mail enviado',
          message: 'Senha temporária enviada para o e-mail !',
        })
      );

      yield put(push('/signin'));

    }


  } catch(error) {

    console.log(error.message)

  }
}


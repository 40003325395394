import styled from 'styled-components';
import { MdAttachMoney, MdDashboard } from 'react-icons/md';
import { FaFileInvoice } from 'react-icons/fa';

export const Container = styled.div`

`;

export const Paper = styled.div`
  background-color: #ffffff;
  box-shadow: -3px 3px 6px #0000000f;
  border-radius: 5px;
  padding: 42px 67px 30px;
  justify-content: center;
  align-items: center;
  @media (min-width: 1280px) {
    height: 624px;
  }
`;

export const Grid = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: 'row';
  align-items: 'center';
  alignContent: 'space-between';
`

export const ItemGrid = styled.div`
  flex: 1 ;
  padding: 20px;
`

export const ItemFilter = styled.div`
  flex: 1 ;
  padding: 20px;
  

`

export const Home = styled(MdDashboard).attrs({
  size: '25px',
})`
  color: #7b7b7b;
  cursor: pointer;

`;

export const Filters =styled.div`
  display: flex;
  flex-direction: 'row';
  align-content: 'space-between';
  flex:1;
  margin-right: 25px;
  
`
export const WrapperFilter = styled.div`
  flex:1;
  flex-direction: 'column';
  align-content: 'space-between';
  width: 60%;
  margin-left: auto;

`

export const WrapperButton= styled.div`
flex:1;
margin-left: auto;
width: 21%;
`

export const IconMoney = styled(MdAttachMoney).attrs({ size: '30px' })`
  color: #fff;
`;

export const InvoicesIcon = styled(FaFileInvoice).attrs({ size: '30px' })`
  color: #fff;
`;
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getInvoicesRequest: null,
  getInvoicesSuccess: ['invoices'],
  getInvoicesFailure: ['error'],
  receiveInvoicesRequest:  ['invoice'],
  receiveInvoicesSuccess:  null,
  receiveInvoicesFailure: ['error'],
});

export const InvoicesTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  invoices: [],
  isLoading: false,
  error: null
});

/* Reducers */

const getInvoicesRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getInvoicesSuccess = (state, { invoices }) =>
state.merge({
  invoices,
  isLoading: false,
});
 
const getInvoicesFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

  const receiveInvoicesRequest = (state) =>
  state.merge({
    isLoading: true,
  });
  
const receiveInvoicesSuccess = (state) =>
state.merge({
  isLoading: false,
});
 
const receiveInvoicesFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_INVOICES_REQUEST]: getInvoicesRequest,
  [Types.GET_INVOICES_SUCCESS]: getInvoicesSuccess,
  [Types.GET_INVOICES_FAILURE]: getInvoicesFailure,
  [Types.RECEIVE_INVOICES_REQUEST]: receiveInvoicesRequest,
  [Types.RECEIVE_INVOICES_SUCCESS]: receiveInvoicesSuccess,
  [Types.RECEIVE_INVOICES_FAILURE]: receiveInvoicesFailure,

});

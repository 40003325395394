import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getReceivablesRequest: null,
  getReceivablesSuccess: ['receivables'],
  getReceivablesFailure: ['error'],
  itemsReceivablesRequest: null,
  itemsReceivablesSuccess: ['receivableItems'],
  itemsReceivablesFailure: ['error'],
  setReceivablesRequest: ['receivable','onClose'], 
  setReceivablesSuccess: null,
  setReceivablesFailure: ['error'],
});

export const ReceivablesTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  receivables: [],
  receivableItems: [],
  isLoading: false,
  error: null
});


/* Reducers */
const getReceivablesRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getReceivablesSuccess = (state, { receivables }) =>
  state.merge({
    receivables,
    isLoading: false,
  });

const getReceivablesFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });


const itemsReceivablesRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const itemsReceivablesSuccess = (state, { receivableItems }) =>
  state.merge({
    receivableItems,
    isLoading: false,
  });

const itemsReceivablesFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

  const setReceivablesRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const setReceivablesSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const setReceivablesFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RECEIVABLES_REQUEST]: getReceivablesRequest,
  [Types.GET_RECEIVABLES_SUCCESS]: getReceivablesSuccess,
  [Types.GET_RECEIVABLES_FAILURE]: getReceivablesFailure,
  [Types.ITEMS_RECEIVABLES_REQUEST]: itemsReceivablesRequest,
  [Types.ITEMS_RECEIVABLES_SUCCESS]: itemsReceivablesSuccess,
  [Types.ITEMS_RECEIVABLES_FAILURE]: itemsReceivablesFailure,
  [Types.SET_RECEIVABLES_REQUEST]: setReceivablesRequest,
  [Types.SET_RECEIVABLES_SUCCESS]: setReceivablesSuccess,
  [Types.SET_RECEIVABLES_FAILURE]: setReceivablesFailure,
})

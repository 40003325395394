import React, { useEffect, useState } from 'react';
import Paper from 'components/Paper';
import TooltipIcon from 'components/TooltipIcon';
import Table from 'components/Table';
import SearchInput from 'components/SearchInput';
import { Row } from 'styles/components';
import { format } from 'date-fns';
import ReceivablesActions from 'store/ducks/receivables';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button'

import {
    Container,
    WrapperTable,
    WrapperAdd,
    Loading,
    IconReceive,
    EyeIcon
} from './styles'
import { useHistory } from 'react-router';

export default function Receivables() {

    const dispatch = useDispatch();
    const history = useHistory();
    const isLoading = useSelector((state) => state.receivables.isLoading);
    const { receivables } = useSelector((state) => state.receivables);

    const [search, setSearch] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

     const data =  receivables?.map((item) => ({
        ...item,
        actions: item.id,
        social_reason: item.name,
        fantasy_name: item.surname,
        created_at: item.created_at,
        status : item.status.length == 0 ? 'Em Analise' : item.status == '1' ? 'Aprovada' : 'Rejeitada'
      }))

    const filteredData = data ? data.filter(
        (item) =>
            item.customer.replace(/\D/g, "").includes(search.replace(/\D/g, "")) ||
            item.social_reason.toUpperCase().includes(search.toUpperCase()) ||
            item.fantasy_name.toUpperCase().includes(search.toUpperCase())
        
    ) : []

    const renderTooltip = (Icon, mouseOver) => (

        <TooltipIcon
            placement="top-start"
            backgroundColor='#FF6464'
            fontSize='10px'
            color='white'
            mouseOver={mouseOver}
            Icon={Icon}
        />

    );

    useEffect(() => {
        dispatch(ReceivablesActions.getReceivablesRequest());
    }, [dispatch]);


    return (
        <Container>
            <Loading
                active={isLoading}
                spinner
                text='Processando...'
            >

                <Paper
                    title="Antecipação Recebíveis"
                    icon={<IconReceive />}
                    rightContent={
                        <SearchInput
                            onChange={(e) => setSearch(e.target.value)}
                            onClear={() => setSearch('')}
                            value={search}
                        />
                    }
                >

                    <WrapperAdd>
                        <Button
                            margin='-15px -15px -15px 30px'
                            width="110px"
                            height="35px"
                            label="Adicionar"
                            onClick={()=>{ history.push('/createReceivables')}}
                        />

                    </WrapperAdd>

                    <WrapperTable>
                        <Table
                            columns={[
                                { label: 'Ações', id: 'actions' },
                                { label: 'Situação', id: 'status' },
                                { label: 'Empresa', id: 'customer' },
                                { label: 'Razâo Social', id: 'social_reason' },
                                { label: 'Nome Fantasia', id: 'fantasy_name' },
                                { label: 'Data Solicitação', id: 'created_at' },
                                { label: 'Valor', id: 'amount' },
                            ]}
                            activePage={0}
                            totalPages={2}
                            data={filteredData}
                            customComponent={{
                                actions: (id) => (
                                    <Row>
                                        {renderTooltip(<EyeIcon
                                            onClick={(e) => {
                                                setSelectedId(id);
                                                setAnchorEl(e.currentTarget);

                                            }}
                                        />, "Visualizar Antecipação")}

                                    </Row>
                                ),
                               created_at: (e) => format(new Date(e), 'dd/MM/yyyy'),
                                //  value: (e) => e.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                            }}
                        />
                    </WrapperTable>

                </Paper>



            </Loading>
        </Container >
    )

}
import React, { memo } from 'react';
import { string, func, element, bool } from 'prop-types';
import { InputAdornment } from '@material-ui/core';

import { Container, TextInput } from './styles';

function CustomTextField({
  label,
  name,
  type,
  placeholder,
  helperText,
  error,
  value,
  onChange,
  fullWidth,
  icon,
  margin,
  color,
  disabled,
}) {
  return (
    <Container margin={margin}>
      <TextInput
        label={label}
        name={name}
        type={type}
        placeholder={placeholder}
        helperText={helperText}
        error={error}
        value={value}
        onChange={onChange}
        fullWidth={fullWidth}
        disabled={disabled}
        InputProps={{
          startAdornment: icon && (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        }}
        color={color}
      />
    </Container>
  );
}

CustomTextField.propTypes = {
  label: string,
  name: string,
  type: string,
  placeholder: string,
  helperText: string,
  error: string,
  value: string,
  onChange: func,
  fullWidth: bool,
  icon: element,
  margin: string,
  color: string,
  disabled: bool,
};

CustomTextField.defaultProps = {
  label: '',
  name: '',
  type: 'text',
  placeholder: '',
  helperText: '',
  error: '',
  value: '',
  fullWidth: false,
  icon: null,
  onChange: () => {},
  margin: null,
  color: 'primary',
  disabled: false,
};

export default memo(CustomTextField);

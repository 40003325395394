import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { number,  string,object } from 'prop-types';

import { Container} from './styles';

export default function TooltipIcon({ placement, fontSize, mouseOver, backgroundColor, Icon, color }) {
    return (
        <Container>
            <OverlayTrigger
                placement={ placement || "top-start" }
                delay={{ show: 150, hide: 50 }}
                overlay={
                    <Tooltip id="icon-tooltip" style={{
                        backgroundColor: backgroundColor,
                        fontSize: { fontSize },
                        padding: '2px 8px',
                        color: color,
                        borderRadius: 3,
                        marginBottom: 3,

                    }}>
                        {mouseOver}
                    </Tooltip>
                }>
                {Icon}
            </OverlayTrigger >
        </Container>
    );
}

TooltipIcon.propTypes = {
    placement: string.isRequired,
    fontSize: number.isRequired,
    mouseOver: string.isRequired,
    backgroundColor: string,
    Icon: object,
    color: string
};

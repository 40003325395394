import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { makeStyles } from '@material-ui/core';
import { FaWhatsapp,FaPhoneSquare,FaUserCheck } from 'react-icons/fa';
import { MdAccountBox,MdAssignment,MdEmail } from 'react-icons/md';

export const useStyles = makeStyles((theme) => ({
  mask: {
    
    padding:0,
    margin: 0,
    fontSize:'15px',
    color:'#112850',
    fontWeight:'400',
  },

  icon:{

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

  }

}));


export const Container = styled.div`
  width: 90vw;
  max-width: 518px;
`;

export const WrapperBody = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 400px;
`;

export const WrapperPhone = styled.div`
display: flex;
flex-direction: row;
justify-content:space-between;
width: 100%;
max-width: 400px;
`;


export const WrapperBottom = styled.div`
  height: 66px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BottomText = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #145b99;
  cursor: pointer;
  padding: 5px 20px;
`;


export const WrapperInput = styled.div`
  padding: 15px 0;
  width: 100%;
`;

export const WrapperIcon = styled.div`

  height:32px;
  border-bottom: 1px solid ${(props) => (props.error ? '#F27160' : '#112850')};

`;


export const InputFile = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

export const Loading = styled(ReactLoading).attrs({
  color: '#145b99',
})``;

export const ReactiveText = styled.p`
  color: #145b99;
  font-size: 16px;
  font-weight: 600;
`;

export const Input = styled.input.attrs((props) => ({
  ref: props.ref,
  type: props.type,
  pattern: props.pattern,
  
}))`
  -webkit-appearance: none;
  height: ${(props) => props.height || '32px'};
  padding: 0 12px;
  background-color: #fff;
  border: 0px;
  border-bottom: 1px solid ${(props) => (props.error ? '#F27160' : '#112850')};
  border-radius: 3px;
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;
  width: 100%;

  ::placeholder {
    font-weight: 400;
  };

  transition: 200ms;
`;

export const WhatsappIcon = styled(FaWhatsapp).attrs({ size: '22px' })`
  margin: 5px;
  color: #6abf62;
  cursor: pointer;

`;

export const UserLogin = styled(FaUserCheck).attrs({ size: '22px' })`
  margin: 5px;
  color: #145b99;
  cursor: pointer;

`;


export const PhoneIcon = styled(FaPhoneSquare).attrs({ size: '21px' })`
  margin-top: 5px;
  margin-right: 5px;
  color: #145b99;
  cursor: pointer;


`;

export const AccountIcon = styled(MdAccountBox).attrs({ size: '24px' })`
  margin-right: 5px;
  color: #145b99;
  cursor: pointer;

`;

export const EmailIcon = styled(MdEmail).attrs({ size: '24px' })`
  margin-right: 5px;
  color: #145b99;
  cursor: pointer;

`;


export const Identify = styled(MdAssignment).attrs({ size: '24px' })`
  margin-top: 5px;
  margin-right: 5px;
  color: #145b99;
  cursor: pointer;

`;



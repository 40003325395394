import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export const Logo = styled.img`
  height: 82px;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  width: 90%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  text-align: center;
  color: #efefef;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 25px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export const BackButton = withStyles({
  root: {
    color: '#fff',
    fontSize: 13,
    borderRadius: 3,
    border: '1px solid #FFF28D',
    fontWeight: 700,
    marginTop: 60,
    backgroundColor: 'transparent',
   
  },
})(Button);

export const EnterButton = withStyles({
  root: {
    color: '#122D58',
    fontSize: 13,
    borderRadius: 3,
    fontWeight: 700,
    marginTop: 60,
    backgroundColor: '#145b99',
    '&:hover': {
      backgroundColor: '#1666ab',
    },
  },
})(Button);

export const ButtonText = styled.span`
  padding: 0 10px;
  color: #fff
`;


import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import sizeMe from 'react-sizeme';

import Paper from 'components/Paper';
import Button from 'components/Button'
import Section from 'components/Section'
import ReceivablesActions from 'store/ducks/receivables';
import { useAlert } from 'react-alert';

import Grid from 'components/DataGrid';

import {
    Container,
    WrapperButtons,
    WrapperInput,
    AddIcon,
    FieldText,
    WrapperField,
    FieldValue,
    Row,
    Loading,
    useStyles
} from './styles'

function CreateReceivables(props) {
    const dispatch = useDispatch()
    const alert = useAlert();

    const history = props.history
    const { width } = props.size;
    const { receivableItems, isLoading } = useSelector((state) => state.receivables);


    const classes = useStyles();
    const [rows, setRows] = useState([])
    const [isSelected, setIsSelected] = useState([])
    const [available, setAvailable] = useState('0,00')
    const [selectedValue, setSelectedValue] = useState('0,00')
    const [receivables] = receivableItems


    const columns = [
        { field: 'number', headerName: 'Número', width: width * 0.15, sortable: false },
        { field: 'installments', headerName: 'Parcela', width: width * 0.10, sortable: false },
        { field: 'created_at', headerName: 'Emissão', width: width * 0.15, sortable: false },
        { field: 'expiry_date', headerName: 'Vencimento', width: width * 0.15, sortable: false },
        { field: 'available_value', headerName: 'Valor Disponível', width: width * 0.32, sortable: false },
    ]

    const handleChecked = (values) => {

        let valueSelected = 0

        if (isSelected.length === 0 && values.length === receivables.tickets.length) {

            for (const item of receivables.tickets) {

                valueSelected += item ? item.available_value : 0

            }

            valueSelected = (Math.round(valueSelected * 100) / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 })
            setSelectedValue(valueSelected)
            setIsSelected(values)
            return
        }

        if (values.length >= isSelected.length) {

            //Atualiza valor selecionado
            for (const item of values) {

                const [data] = receivables.tickets.filter((e, index) => {
                    if (e.number + e.installments.trim() === item) {

                        //valido se o item anterior esta marcado
                        const idChecked = index > 0 ? values.filter((i) => i === receivables.tickets[index - 1].number + receivables.tickets[index - 1].installments.trim()) : [values[index]]

                        if (idChecked.length === 0) {
                            alert.info("Os itens devem ser selecionados por ordem de emissão, menor para o maior !")
                            values.pop()
                            return false
                        }

                        return e

                    }
                })

                valueSelected += data ? data.available_value : 0
            }

        } else {

            let mark = false

            if (values.length > 0) {

                //valido se o item anterior esta marcado           
                for (const item of isSelected) {

                    const [data] = receivables.tickets.filter((e) => item === e.number + e.installments.trim())
                    const result = values.filter((e) => item === e)

                    if (result.length === 0) {

                        if (item === isSelected[isSelected.length - 1]) {
                            mark = true
                        } else {

                            valueSelected += data ? data.available_value : 0

                        }

                    } else {

                        valueSelected += data ? data.available_value : 0

                    }
                }

                if (!mark) {
                    alert.info("Os itens devem ser desmarcados por ordem de emissão, maior para o menor  !")
                    values = isSelected
                }

            }

        }

        valueSelected = (Math.round(valueSelected * 100) / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 })
        setSelectedValue(valueSelected)
        setIsSelected(values)

    }

    const handleClick = () => {

        if (!isLoading) {

            const receivable = {
                selected: [],
                amount: receivables.amount,
                branch: receivables.branch,
                customer: receivables.customer,
                group: receivables.group
            }

            if (selectedValue === '0,00') {

                alert.info(
                    'Nenhum valor foi selecionado para antecipação !'
                );

                return
            }

            //prepara dados para gravacao
            isSelected.forEach((item) => {

                const [data] = receivables.tickets.filter((e) => e.number.trim() + e.installments.trim() === item)
                receivable.selected.push({
                    id: item,
                    receivable: data
                })
            })

            dispatch(ReceivablesActions.setReceivablesRequest({ ...receivable }, onClose))

        }

    }

    const onClose = () => {
        history.goBack()
    }


    useEffect(() => {

        if (receivableItems.length > 0) {

            const [data] = receivableItems
            const receivable = []

            data.tickets?.forEach(item => {

                receivable.push({
                    number: item.number,
                    installments: item.installments.trim(),
                    created_at: item.created_at.substr(6, 2) + '/' + item.created_at.substr(4, 2) + "/" + item.created_at.substr(0, 4),
                    expiry_date: item.expiry_date.substr(6, 2) + '/' + item.expiry_date.substr(4, 2) + "/" + item.expiry_date.substr(0, 4),
                    available_value: Math.round(item.available_value * 100) / 100,
                    id: item.number + item.installments.trim()
                })
            });

            if (receivables.amount) {

                setAvailable((Math.round(receivables.amount * 100) / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 }))
                setRows(receivable)
            }

        }
    }, [receivableItems]);


    useEffect(() => {
        dispatch(ReceivablesActions.itemsReceivablesRequest());
    }, [dispatch]);



    return (
        <>
            <Paper title={"NOVA ANTECIPAÇÃO"} icon={<AddIcon />} ></Paper>
            <Container>
                <WrapperButtons>

                    <Button
                        type="secondary"
                        label="Cancelar"
                        width="130px"
                        height="33px"
                        margin='0px 20px 0px'
                        onClick={() => onClose()}
                    />
                    <Button
                        type="primary"
                        label="Salvar"
                        width="130px"
                        height="33px"
                        margin='0px 0px 20px'
                        onClick={handleClick}
                    />
                </WrapperButtons>

                <Row>
                    <WrapperField>

                        <FieldText>TOTAL DISPONÍVEL</FieldText>
                        <FieldValue className={classes.available}>R$ {available}</FieldValue>

                    </WrapperField>

                    <WrapperField>

                        <FieldText>TOTAL SELECIONADO</FieldText>
                        <FieldValue >R$ {selectedValue}</FieldValue>

                    </WrapperField>

                </Row>

                <Section title="Valores Disponíveis">
                    <Loading
                        active={isLoading}
                        spinner
                        text='Processando...'
                    >
                        <WrapperInput>
                            <Grid
                                columns={columns}
                                rows={rows}
                                onRowSelected={(e) => handleChecked(e)}
                                selectionModel={isSelected}
                                height={`${width * 0.30}px`}
                                checkboxSelection={true}
                                hideFooterPagination={false}
                                pageSize={10}
                            />
                        </WrapperInput>
                    </Loading>

                </Section>

            </Container>
        </>
    )
}

export default sizeMe({ monitorHeight: true })(CreateReceivables);